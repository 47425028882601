import React, { ReactNode } from "react";
import { Footer } from "./Footer";
import { withStyles, Theme, WithStyles, createStyles } from "@material-ui/core";
import classNames from "classnames";
import { palette } from "../../css/theme";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      paddingTop: "64px",
      marginTop: "64px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px"
      },
      backgroundColor: palette.white
    },
    container: {
      textAlign: "center",
      margin: "auto",
      paddingLeft: "8px",
      paddingRight: "8px",
      maxWidth: theme.breakpoints.width("lg"),
      [theme.breakpoints.between("md", "lg")]: {
        width: "90%"
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingLeft: "0",
        paddingRight: "0"
      }
    }
  });

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  footer?: boolean;
  navBar: ReactNode;
}

class Layout extends React.Component<LayoutProps> {
  render() {
    const { className, children, footer, navBar } = this.props;
    const { layout, container } = this.props.classes;
    return (
      <div className={classNames(layout, className)}>
        {navBar}
        <div className={container}>{children}</div>
        {footer && <Footer />}
      </div>
    );
  }
}

export default withStyles(styles)(Layout);
