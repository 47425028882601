import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import { Paper, Typography, TableCell, TableRow, TableBody, Table, TableHead } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Order } from "../../../models/Order";
import { Product } from "../../../models/Product";

interface OrderRecapProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  orders: Map<string, Order>;
  products: Map<string, Product>;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "left-align",
      overflow: "hidden",
      width: "100%",
      backgroundColor: theme.palette.background.paper
    }
  });

const OrderRecapRaw: FunctionComponent<OrderRecapProps> = props => {
  const { root } = props.classes;
  const { orders, products } = props;

  const sortOrders = (orders: Map<string, Order>, products: Map<string, Product>) => {
    const productTypes: Record<string, number> = {};
    return orders.reduce((previous, order) => {
      return order.products.reduce((previous, product) => {
        const productInfo = products.get(product.productId);
        if (!productInfo) return previous;
        const previousValue = previous[productInfo.price.toString()];
        previous[productInfo.price.toString()] = previousValue ? previousValue + 1 : 1;
        return previous;
      }, previous);
    }, productTypes);
  };

  const total = orders.reduce(
    (previous, order) => ({ total: previous.total + order.total, count: previous.count + order.products.length }),
    { total: 0, count: 0 }
  );

  const sortedOrders = sortOrders(orders, products);
  console.log(sortedOrders);

  return (
    <Paper className={root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: "24px" }} padding="none">
              <Typography>Prix</Typography>
            </TableCell>
            <TableCell style={{ padding: "24px" }} padding="none">
              <Typography>Quantité</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(sortedOrders).map((key: string) => (
            <TableRow key={key}>
              <TableCell style={{ padding: "24px" }} padding="none">
                <Typography>{key}€</Typography>
              </TableCell>
              <TableCell style={{ padding: "24px" }} padding="none">
                <Typography>{sortedOrders[key]}</Typography>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell style={{ padding: "24px" }} padding="none">
              <Typography variant="h3" color="primary">
                {total.total}€
              </Typography>
            </TableCell>
            <TableCell style={{ padding: "24px" }} padding="none">
              <Typography variant="h3" color="primary">
                {total.count}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export const OrderRecap = withStyles(styles)(OrderRecapRaw);
