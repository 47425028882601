import { Action } from "../index";
import { Reducer } from "redux";
import { Map } from "immutable";
import { Order } from "../../models/Order";
import { ActionType } from "typesafe-actions";

export type OrderAction = ActionType<typeof import("./actions")>;
export class OrderState {
  readonly userOrders: Map<string, Order> = Map();
  readonly dailyOrders: Map<string, Order> = Map();
  readonly enabled: boolean = false;
  readonly delivery: boolean = false;
  readonly loading: boolean = false;
  readonly loadingFailed: boolean = false;
  readonly creating: boolean = false;
  readonly deleting: boolean = false;
}

type OrdersReducer = Reducer<OrderState, Action>;

export const ordersReducer: OrdersReducer = function(state = new OrderState(), action): OrderState {
  switch (action.type) {
    case "ORDERS/ENABLED":
      return {
        ...state,
        delivery: action.payload.delivery,
        enabled: action.payload.enabled
      };
    case "ORDERS/LIST_ADMIN":
    case "ORDERS/LIST_USER":
      return {
        ...state,
        loading: true,
        loadingFailed: false
      };
    case "ORDERS/LIST_ADMIN_SUCCESS": {
      const previousList = state.dailyOrders;
      const list = previousList.merge(action.payload.map((order: Order) => [order.id, order]));
      return {
        ...state,
        loading: false,
        dailyOrders: list
      };
    }
    case "ORDERS/LIST_USER_SUCCESS": {
      const previousList = state.userOrders;
      const list = previousList.merge(action.payload.map((order: Order) => [order.id, order]));
      return {
        ...state,
        loading: false,
        userOrders: list
      };
    }
    case "ORDERS/LIST_ADMIN_FAILED":
    case "ORDERS/LIST_USER_FAILED":
      return {
        ...state,
        loading: false,
        loadingFailed: true
      };
    case "ORDERS/CREATE":
      return {
        ...state,
        creating: true
      };
    case "ORDERS/CREATE_SUCCESS": {
      return {
        ...state,
        creating: false,
        dailyOrders: state.dailyOrders.set(action.payload.id, action.payload),
        userOrders: state.userOrders.set(action.payload.id, action.payload)
      };
    }
    case "ORDERS/CREATE_FAILED": {
      return {
        ...state,
        creating: false
      };
    }
    case "ORDERS/DELETE": {
      return {
        ...state,
        deleting: true
      };
    }
    case "ORDERS/DELETE_SUCCESS": {
      return {
        ...state,
        deleting: false,
        dailyOrders: state.dailyOrders.remove(action.payload),
        userOrders: state.userOrders.remove(action.payload)
      };
    }
    case "ORDERS/DELETE_FAILED": {
      return {
        ...state,
        deleting: false
      };
    }
    default:
      return state;
  }
};
