import { Action } from "../index";
import { Reducer } from "redux";
import { Map } from "immutable";
import { ProductOrder } from "../../models/Order";
import { ActionType } from "typesafe-actions";
import { v4 as uuid } from "uuid";

export type CartAction = ActionType<typeof import("./actions")>;
export class CartState {
  readonly products: Map<string, ProductOrder> = Map();
  readonly delivery?: string;
}

type CartsReducer = Reducer<CartState, Action>;

export const cartReducer: CartsReducer = function(state = new CartState(), action): CartState {
  switch (action.type) {
    case "CART/ADD":
      const newProducts = Map(action.payload.map(product => [uuid(), product]));
      return {
        ...state,
        products: state.products.merge(newProducts)
      };
    case "CART/REMOVE":
      return {
        ...state,
        products: state.products.delete(action.payload)
      };
    case "CART/SET_DELIVERY":
      return {
        ...state,
        delivery: action.payload
      };
    case "CART/CLEAR":
      return {
        products: Map(),
        delivery: undefined
      };
    default:
      return state;
  }
};
