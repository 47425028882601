import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { Map } from "immutable";
import { OrderState } from "../../../store/orders/reducer";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { OrderList } from "./OrderList";
import { Product } from "../../../models/Product";
import { TimeSlot } from "../../../models/TimeSlot";
import { listDailyOrders, setOrdersEnabled } from "../../../store/orders/thunks";
import { OrderRecap } from "./OrderRecap";
import { showModal } from '../../../store/application/actions';
import { ModalName, Modal } from "../../../store/application/modal";

interface OrderListProps extends WithStyles<typeof styles> {
  orders: OrderState;
  products: Map<string, Product>;
  timeslots: Map<string, TimeSlot>;
  listDailyOrders: () => void;
  setOrdersEnabled: (enabled: boolean) => void;
  showModal:  (modal: Modal) => void;
}

const styles = () =>
  createStyles({
    sectionTitle: {
      marginBottom: "8px"
    }
  });

class OrdersRaw extends React.Component<OrderListProps> {
  interval?: NodeJS.Timeout;

  componentDidMount() {
    this.props.listDailyOrders();
    this.interval = setInterval(() => {
      this.props.listDailyOrders();
    }, 30000);
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  startOrders = () => {
    this.props.setOrdersEnabled(true);
  };

  stopOrders = () => {
    this.props.setOrdersEnabled(false);
  };

  resetOrders = () => {
    this.props.showModal({ id: ModalName.resetOrders, resetOrders: this.startOrders })
  }

  render() {
    const { timeslots, products } = this.props;
    const { enabled, dailyOrders: list } = this.props.orders;
    const { sectionTitle } = this.props.classes;
    return (
      <Grid container direction="column">
        <Grid item>
          <Grid className={sectionTitle} container direction="row" justify="space-between">
            <Grid item>
              <Typography variant="h2"> {enabled ? "Commandes" : "Recap Commandes"} </Typography>
            </Grid>
            <Grid item>
              <Grid className={sectionTitle} container direction="row" spacing={2}>
                {enabled && (
                  <React.Fragment>
                    <Grid item>
                      <Button variant="contained" onClick={this.resetOrders}>
                        Réinitialiser
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={this.stopOrders}>
                        Clôturer
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item>
                  <Button variant="contained" color="primary">
                    <Link color="inherit" component={RouterLink} to="products/availability">
                      Disponibilité Produits
                    </Link>
                  </Button>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {!enabled && list && list.size > 0 && <OrderRecap products={products} orders={list} />}
          {!enabled && list && list.size === 0 && (
            <Button variant="contained" onClick={this.startOrders}>
              Démarrer
            </Button>
          )}
          {enabled && !!timeslots && timeslots.size > 0 && <OrderList orders={list} timeslots={timeslots} products={products} />}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    orders: state.orders,
    timeslots: state.timeslots.list,
    products: state.products.list
  };
}

export const Orders = withStyles(styles)(
  connect(
    mapStateToProps,
    { listDailyOrders, setOrdersEnabled, showModal }
  )(OrdersRaw)
);
