import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import Layout from "../../Layout/Layout";
import { connect } from "react-redux";
import { resetPassword } from "../../../store/auth/thunks";

type ResetPasswordPayload = {
  email: string;
  code: string;
  password: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    paper: {
      margin: "16px"
    }
  });

interface FormikResetPasswordProps
  extends FormikProps<ResetPasswordPayload>,
    ConnectedResetPasswordProps,
    WithStyles<typeof styles> {}

class ResetPassword extends React.Component<FormikResetPasswordProps> {
  render() {
    const { values, errors, handleChange, touched, handleBlur, history, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle, paper } = this.props.classes;

    return (
      <Layout navBar={<div />}>
        <Grid
          className={sectionTitle}
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
        >
          <Grid item xs>
            <Typography align="left" variant="h2">
              Réinitialiser le mot de passe
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper className={paper}>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField autoComplete="email" type="email" name="email" label="Email" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="code" label="Code de vérification" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  label="Mot de passe"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>

              <Grid item xs={12}>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                  Valider
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Layout>
    );
  }
}

const StyledResetPassword = withStyles(styles)(ResetPassword);

const FormikResetPassword = withFormik<ConnectedResetPasswordProps, ResetPasswordPayload>({
  mapPropsToValues: props => {
    return {
      email: props.email || "",
      code: "",
      password: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, resetPassword } = formikBag.props;
    const { email, code, password } = values;
    const success = await resetPassword(email, code, password);
    if (success) {
      history.replace("/signin");
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Email invalide")
      .required("Champ requis"),
    code: yup.string().required("Code requis. Vérifiez vos spams"),
    password: yup.string().min(10, "Votre mot de passe doit comporter 10 caractères minimum")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledResetPassword);

interface ConnectedResetPasswordProps extends RouteComponentProps {
  email?: string;
  resetPassword: (email: string, code: string, password: string) => Promise<boolean>;
}

export default connect(
  null,
  { resetPassword }
)(FormikResetPassword);
