import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import { Paper, Typography, Grid, TableBody, Table, TableRow, TableCell, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { TimeSlot } from "../../../models/TimeSlot";

interface TimeSlotListProps extends React.HTMLAttributes<HTMLDivElement> {
  timeslots: Map<string, TimeSlot>;
}

export const TimeSlotList: FunctionComponent<TimeSlotListProps> = props => {
  const { timeslots, className } = props;

  return (
    <Paper className={className} elevation={1}>
      <Table>
        <TableBody>
          {timeslots &&
            timeslots.entrySeq().map(([key, timeslot]) => {
              return (
                <TableRow key={key}>
                  <TableCell style={{ padding: "24px" }} padding="none">
                    <Link color="textPrimary" component={RouterLink} to={`timeslots/${key}`}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1">{`${timeslot.start}-${timeslot.end}`} </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            Pizzas: {timeslot.capacity.pizza}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            Kebab: {timeslot.capacity.kebab}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            Chicken Wings: {timeslot.capacity.chickenWing}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            Boissons: {timeslot.capacity.beverage}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            Livraisons: {timeslot.capacity.delivery}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};
