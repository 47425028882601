import { Action } from "../index";
import { Reducer } from "redux";
import { Map } from "immutable";
import { Location } from "../../models/Location";
import { ActionType } from "typesafe-actions";

export type LocationAction = ActionType<typeof import("./actions")>;
export class LocationState {
  readonly list: Map<string, Location> = Map();
  readonly loading: boolean = false;
  readonly loadingFailed: boolean = false;
  readonly creating: boolean = false;
  readonly deleting: boolean = false;
}

type LocationsReducer = Reducer<LocationState, Action>;

export const locationsReducer: LocationsReducer = function(state = new LocationState(), action): LocationState {
  switch (action.type) {
    case "LOCATIONS/LIST":
      return {
        ...state,
        loading: true,
        loadingFailed: false
      };
    case "LOCATIONS/LIST_SUCCESS": {
      const previousList = state.list;
      const sortedList = action.payload.sort((a, b) => (a.name < b.name ? -1 : 1));
      const list = previousList.mergeDeep(sortedList.map((location: Location) => [location.id, location]));
      return {
        ...state,
        loading: false,
        list
      };
    }
    case "LOCATIONS/LIST_FAILED":
      return {
        ...state,
        loading: false,
        loadingFailed: true
      };
    case "LOCATIONS/CREATE":
      return {
        ...state,
        creating: true
      };
    case "LOCATIONS/CREATE_SUCCESS": {
      const list = state.list.set(action.payload.id, action.payload).sort((a, b) => (a.name < b.name ? -1 : 1));
      return {
        ...state,
        creating: false,
        list
      };
    }
    case "LOCATIONS/CREATE_FAILED": {
      return {
        ...state,
        creating: false
      };
    }
    case "LOCATIONS/DELETE": {
      return {
        ...state,
        deleting: true
      };
    }
    case "LOCATIONS/DELETE_SUCCESS": {
      const list = state.list.remove(action.payload);
      return {
        ...state,
        deleting: false,
        list
      };
    }
    case "LOCATIONS/DELETE_FAILED": {
      return {
        ...state,
        deleting: false
      };
    }
    default:
      return state;
  }
};
