import { Modal, ModalName } from "./modal";
import { FlashMessage, FlashMessageType } from "./flashMessage";

import { createAction } from "typesafe-actions";

export const initializingApp = createAction("APPLICATION/INITIALIZING");
export const initializedApp = createAction("APPLICATION/INITIALIZED");

export const showFlash = createAction("APPLICATION/SHOW_FLASH", (action) => {
  return (message: string, type: FlashMessageType) => action(new FlashMessage(message, type));
});

export const hideFlash = createAction("APPLICATION/HIDE_FLASH", (action) => {
  return (id: string) => action(id);
});

export const showModal = createAction("APPLICATION/SHOW_MODAL", (action) => {
  return (modal: Modal) => action(modal);
});

export const hideModal = createAction("APPLICATION/HIDE_MODAL", (action) => {
  return (id: ModalName) => action(id);
});
