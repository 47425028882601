import { User } from "../../models/User";
import { createAction } from "typesafe-actions";
import { Payload } from "../../models/Payload";

export const signUp = createAction("AUTH/SIGNUP");
export const signUpSuccess = createAction("AUTH/SIGNUP_SUCCESS", action => {
  return (user: Payload<User>, password: string) => action({ user, password });
});
export const signUpFailed = createAction("AUTH/SIGNUP_FAILED", action => {
  return (error: Error) => action(error);
});

export const confirmEmail = createAction("AUTH/CONFIRM_EMAIL");
export const confirmEmailSuccess = createAction("AUTH/CONFIRM_EMAIL_SUCCESS", action => {
  return (user: User) => action(user);
});
export const confirmEmailFailed = createAction("AUTH/CONFIRM_EMAIL_FAILED", action => {
  return (error: Error) => action(error);
});
export const signIn = createAction("AUTH/SIGNIN");
export const signInSuccess = createAction("AUTH/SIGNIN_SUCCESS", action => {
  return (user: User) => action(user);
});
export const signInFailed = createAction("AUTH/SIGNIN_FAILED", action => {
  return (error: Error) => action(error);
});

export const signOut = createAction("AUTH/SIGNOUT");
