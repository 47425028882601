import { Location } from "../../models/Location";
import { createAction } from "typesafe-actions";

export const list = createAction("LOCATIONS/LIST");
export const listSuccess = createAction("LOCATIONS/LIST_SUCCESS", action => {
  return (locations: Location[]) => action(locations);
});
export const listFailed = createAction("LOCATIONS/LIST_FAILED", action => {
  return (error: Error) => action(error);
});

export const create = createAction("LOCATIONS/CREATE");
export const createSuccess = createAction("LOCATIONS/CREATE_SUCCESS", action => {
  return (location: Location) => action(location);
});
export const createFailed = createAction("LOCATIONS/CREATE_FAILED", action => {
  return (error: Error) => action(error);
});

export const remove = createAction("LOCATIONS/DELETE");
export const removeSuccess = createAction("LOCATIONS/DELETE_SUCCESS", action => {
  return (id: string) => action(id);
});
export const removeFailed = createAction("LOCATIONS/DELETE_FAILED", action => {
  return (error: Error) => action(error);
});
