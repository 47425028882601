import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import Layout from "../../Layout/Layout";
import { connect } from "react-redux";
import { forgotPassword } from "../../../store/auth/thunks";

type ForgotPasswordPayload = {
  email: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    paper: {
      margin: "16px"
    }
  });

interface FormikForgotPasswordProps
  extends FormikProps<ForgotPasswordPayload>,
    ConnectedForgotPasswordProps,
    WithStyles<typeof styles> {}

class ForgotPassword extends React.Component<FormikForgotPasswordProps> {
  render() {
    const { values, errors, handleChange, touched, handleBlur, history, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle, paper } = this.props.classes;

    return (
      <Layout navBar={<div />}>
        <Grid
          className={sectionTitle}
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
        >
          <Grid item xs>
            <Typography align="left" variant="h2">
              Réinitialiser le mot de passe
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper className={paper}>
          <Form>
            <Grid className={fields} container alignItems="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="email"
                  type="email"
                  name="email"
                  label="Email"
                  helperText="Un email vous sera envoyé si un compte est associé à cette adresse"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>

              <Grid item xs={12}>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                  Valider
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Layout>
    );
  }
}

const StyledForgotPassword = withStyles(styles)(ForgotPassword);

const FormikForgotPassword = withFormik<ConnectedForgotPasswordProps, ForgotPasswordPayload>({
  mapPropsToValues: props => {
    return {
      email: props.email || ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, forgotPassword } = formikBag.props;
    const success = await forgotPassword(values.email);
    if (success) {
      history.replace("/resetPassword", values);
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Email invalide")
      .required("Champ requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledForgotPassword);

interface ConnectedForgotPasswordProps extends RouteComponentProps {
  email?: string;
  forgotPassword: (email: string) => Promise<boolean>;
}

export default connect(
  null,
  { forgotPassword }
)(FormikForgotPassword);
