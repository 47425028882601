import { Auth } from "aws-amplify";

let COMMON_URL = "";
let CUSTOMER_URL = "";
let ADMIN_URL = "";
const region = "eu-west-3";
const authRegion = "eu-west-2";
let custom_header = undefined;

let cognitoPoolID = process.env.REACT_APP_COGNITO_POOL_ID_STAGING;
let userPoolId = process.env.REACT_APP_USER_POOL_ID_STAGING;
let userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_STAGING;

if (process.env.REACT_APP_ENV !== "staging") {
  cognitoPoolID = process.env.REACT_APP_COGNITO_POOL_ID;
  userPoolId = process.env.REACT_APP_USER_POOL_ID;
  userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
}

switch (process.env.REACT_APP_ENV) {
  case "production":
    COMMON_URL = "https://api.aucamionmagique.com";
    CUSTOMER_URL = COMMON_URL;
    ADMIN_URL = "https://admin.aucamionmagique.com";
    break;
  default:
    const baseURL = "http://localhost";
    COMMON_URL = `${baseURL}:3030`;
    CUSTOMER_URL = `${baseURL}:3040`;
    ADMIN_URL = `${baseURL}:3050`;
    custom_header = async () => {
      const credentials = await Auth.currentCredentials();
      return credentials.authenticated ? { "cognito-identity-id": credentials.identityId } : {};
    };
}

const amplifyConfiguration = {
  API: {
    endpoints: [
      {
        name: "common",
        endpoint: COMMON_URL,
        region,
        custom_header
      },
      {
        name: "admin",
        endpoint: ADMIN_URL,
        region,
        custom_header
      },
      {
        name: "customer",
        endpoint: CUSTOMER_URL,
        region,
        custom_header
      }
    ]
  },
  Auth: {
    identityPoolId: cognitoPoolID,
    region: authRegion,
    userPoolId,
    userPoolWebClientId
  }
};

export default amplifyConfiguration;
