import React, { FunctionComponent } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { Button, Select, FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { RemoveCircleRounded } from "@material-ui/icons";
import { Map, OrderedMap } from "immutable";
import { CartState } from "../../../store/cart/reducer";
import { Product } from "../../../models/Product";
import { createOrder } from "../../../store/orders/thunks";
import {  removeItem, setDelivery } from "../../../store/cart/actions";
import { Typography, Grid, TableBody, Table, TableRow, TableCell, IconButton } from "@material-ui/core";
import { TimeSlot } from "../../../models/TimeSlot";
import { ProductOrder } from "../../../models/Order";
import { Location } from "../../../models/Location";
import { withFormik, FormikProps, Form } from "formik";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import { getAvailableTimeslots } from "../../../utils/timeslots";

interface CartPayload {
  timeslot: string;
  delivery?: string;
}

interface FormikCartRecapProps extends FormikProps<CartPayload>, ConnectedCartRecapProps, WithStyles<typeof styles> {}

const styles = (theme: Theme) =>
  createStyles({
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    sectionTitle: {
      marginBottom: "32px"
    },
    form: {
      width: "60%",
      margin: "auto"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const CartRecapRaw: FunctionComponent<FormikCartRecapProps> = props => {

  const {
    products,
    cart,
    removeItem,
    total,
    timeslots,
    enabled,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    locations,
    delivery,
  } = props;
  const { listContainer, form } = props.classes;

  const noDeliveryMessage = () => {
    const selectedTimeslot = props.timeslots.get(values.timeslot);
    const hasDeliveryOnSelectedTimeslot = selectedTimeslot ? selectedTimeslot.remaining.delivery > 0 : true
    const minimumPriceReached = total >= 10
    
    if (!delivery) return "Pas de livraison disponible ce soir"
    if (!minimumPriceReached) return "Livraison possible à partir de 10€"
    if (!hasDeliveryOnSelectedTimeslot) return "Plus de livraison disponible sur ce créneau"
    return undefined
  }

  const noDeliveryHelperText = noDeliveryMessage();
  
  const onDeliveryChange: React.ChangeEventHandler<{ name?: string; value: unknown }> = (event) => {
    props.setDelivery(event.target.value as string)
    props.handleChange(event);
  }

  if (!enabled) {
    return (
      <div className={listContainer}>
        <Typography variant="h3">Les commandes ne sont pas encore ouvertes pour ce soir, reviens vers 17h30 !</Typography>
      </div>
    );
  }

  return (
    <div className={listContainer}>
      <Table className={"className"}>
        <TableBody>
          {cart.products.entrySeq().map(([key, product]) => {
            const productInfo = products.get(product.productId);
            if (!productInfo) return null;
            return (
              <TableRow key={key}>
                <TableCell style={{ padding: "24px" }} padding="none">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body1">{productInfo.name}</Typography>
                    </Grid>
                    {product.options && (
                      <Grid item>
                        <Typography color="textSecondary" variant="body2">
                          {product.options}
                        </Typography>
                      </Grid>
                    )}
                    {product.flavor && (
                      <Grid item>
                        <Typography color="textSecondary" variant="body2">
                          Sauce {product.flavor}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="body2">{productInfo.price}€</Typography>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid container justify="flex-end" alignItems="center" wrap="nowrap">
                    <Grid item>
                      <IconButton size="small" color="inherit" onClick={() => removeItem(key)}>
                        <RemoveCircleRounded />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Total
              </Typography>
            </TableCell>
            <TableCell>{total}€</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Form>
        <Grid className={form} container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth error={touched.timeslot && !!errors.timeslot}>
              <InputLabel shrink>Horaire</InputLabel>
              <Select name="timeslot" value={values.timeslot} onChange={handleChange} onBlur={handleBlur} fullWidth native>
                <option value="" />
                {timeslots.entrySeq().map(([key, timeslot]) => (
                  <option key={key} value={timeslot.id}>
                    {timeslot.end}
                  </option>
                ))}
              </Select>
              <FormHelperText>{(touched.timeslot && errors.timeslot)}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={touched.timeslot && !!errors.timeslot}>
              <InputLabel shrink>Livraison</InputLabel>
              <Select name="delivery" disabled={!!noDeliveryHelperText} value={values.delivery} onChange={onDeliveryChange} onBlur={handleBlur} fullWidth native>
                <option value=""> Pas de livraison </option>
                {locations.entrySeq().map(([key, location]) => (
                  <option key={key} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Select>
              <FormHelperText>{(touched.timeslot && errors.timeslot) || (!!values.delivery && "Le règlement des commandes se fait auprès du livreur en chèque ou espèces.") || noDeliveryHelperText}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "36px" }}>
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="secondary">
                  Commander
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="default" onClick={props.history.goBack}>
                  Modifier
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

const StyledCartRecap = withStyles(styles)(CartRecapRaw);

const FormikCartRecap = withFormik<ConnectedCartRecapProps, CartPayload>({
  mapPropsToValues: props => {
    return {
      timeslot: props.match.params.timeslotId || "",
      delivery: props.cart.delivery || ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { cart, total, createOrder, history, adminMode } = formikBag.props;
    const { timeslot } = values;
    const delivery = !!values.delivery ? values.delivery : undefined;
    const orderItems = Array.from(cart.products.valueSeq());
    const anonymousOrder = adminMode;
    const success = await createOrder(orderItems, total, timeslot, delivery, anonymousOrder);
    if (success) {
      const route = adminMode ? "/admin/orders" : "/app/orders";
      history.push(route);
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    timeslot: yup.string().required("Champ requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledCartRecap);

interface OuterCartRecapProps extends RouteComponentProps<{ timeslotId?: string }> {
  adminMode: boolean;
}

interface ConnectedCartRecapProps extends OuterCartRecapProps {
  products: Map<string, Product>;
  cart: CartState;
  total: number;
  timeslots: OrderedMap<string, TimeSlot>;
  locations: Map<string, Location>;
  enabled: boolean;
  delivery: boolean;
  removeItem: (id: string) => void;
  setDelivery: (delivery?: string) => void;
  createOrder: (
    products: ProductOrder[],
    total: number,
    timeslot: string,
    delivery?: string,
    anonymous?: boolean
  ) => Promise<boolean>;
}

function mapStateToProps(state: State) {
  const total = state.cart.products.reduce((total, product) => {
    const productInfos = state.products.list.get(product.productId);
    if (!productInfos) return total;
    return total + productInfos.price;
  }, 0);
  const hasDelivery = !!state.cart.delivery
  const timeslots = getAvailableTimeslots(state.cart.products, state.timeslots.list, hasDelivery);
  return {
    enabled: state.orders.enabled,
    delivery: state.orders.delivery,
    products: state.products.list,
    locations: state.locations.list,
    cart: state.cart,
    timeslots,
    total
  };
}

export const CartRecap = connect(
  mapStateToProps,
  { removeItem, createOrder, setDelivery }
)(FormikCartRecap);
