export const storageHelper = {
  getItem: <T extends {}>(key: string): T | undefined => {
    const data = window.localStorage.getItem(key);
    if (!data) return undefined;
    return JSON.parse(data);
  },

  setItem: <T>(key: string, item: T) => {
    const data = JSON.stringify(item);
    window.localStorage.setItem(key, data);
  },

  clear: () => {
    window.localStorage.clear();
  }
};
