import { Product } from "../../models/Product";
import { createAction } from "typesafe-actions";

export const list = createAction("PRODUCTS/LIST");
export const listSuccess = createAction("PRODUCTS/LIST_SUCCESS", action => {
  return (products: Product[]) => action(products);
});
export const listFailed = createAction("PRODUCTS/LIST_FAILED", action => {
  return (error: Error) => action(error);
});

export const create = createAction("PRODUCTS/CREATE");
export const createSuccess = createAction("PRODUCTS/CREATE_SUCCESS", action => {
  return (product: Product) => action(product);
});
export const createFailed = createAction("PRODUCTS/CREATE_FAILED", action => {
  return (error: Error) => action(error);
});

export const remove = createAction("PRODUCTS/DELETE");
export const removeSuccess = createAction("PRODUCTS/DELETE_SUCCESS", action => {
  return (id: string) => action(id);
});
export const removeFailed = createAction("PRODUCTS/DELETE_FAILED", action => {
  return (error: Error) => action(error);
});
