import { createAction } from "typesafe-actions";
import { Infos } from "../../models/Infos";

export const infos = createAction("INFOS/GET");
export const infosSuccess = createAction("INFOS/GET_SUCCESS", (action) => {
  return (infos: Infos) => action(infos);
});
export const infosFailed = createAction("INFOS/GET_FAILED", (action) => {
  return (error: Error) => action(error);
});

export const createInfos = createAction("INFOS/CREATE");
export const createInfosSuccess = createAction("INFOS/CREATE_SUCCESS", (action) => {
  return () => action();
});
export const createInfosFailed = createAction("INFOS/CREATE_FAILED", (action) => {
  return (error: Error) => action(error);
});
