import { Map, OrderedMap } from "immutable";
import { ProductOrder } from "../models/Order";
import moment from "moment";
import { TimeSlot, SlotCapacity } from "../models/TimeSlot";

export const getAvailableTimeslots = (
  products: Map<string, ProductOrder>,
  timeslots: OrderedMap<string, TimeSlot>,
  delivery?: boolean,
  excludeStarted: boolean = true
) => {
  const typeTotals: Record<SlotCapacity, number> = {
    pizza: products.filter(product => product.productType === "pizza").count(),
    kebab: products.filter(product => product.productType === "kebab").count(),
    chickenWing: products.filter(product => product.productType === "chickenWing").count(),
    beverage: products.filter(product => product.productType === "beverage").count(),
    delivery: !!delivery ? 1 : 0
  };

  const time = moment().format("HH:mm");

  return timeslots.filter(
    timeslot =>
      timeslot.remaining.pizza >= typeTotals.pizza &&
      timeslot.remaining.kebab >= typeTotals.kebab &&
      timeslot.remaining.chickenWing >= typeTotals.chickenWing &&
      timeslot.remaining.beverage >= typeTotals.beverage &&
      timeslot.remaining.delivery >= typeTotals.delivery &&
      timeslot.available &&
      (excludeStarted ? timeslot.start > time : true)
  );
};
