import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Layout } from "../Layout";
import { AppNavBar } from "../Layout/NavBar";
import { Switch, Route } from "react-router";
import { Cart } from "./Cart/Cart";
import { CartRecap } from "./Cart/CartRecap";
import { Orders } from "./Orders/Orders";
import { Infos } from './Infos/Infos';

interface ApplicationProps extends WithStyles<typeof styles> {}

export const styles = (theme: Theme) => createStyles({});

const Application: FunctionComponent<ApplicationProps> = props => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <Layout navBar={<AppNavBar />}>
      <Switch>
        <Route exact path="/app/infos" component={Infos} />
        <Route exact path="/app/cart/review" render={props => <CartRecap {...props} adminMode={false} />} />
        <Route exact path="/app/cart" render={props => <Cart {...props} adminMode={false} />} />
        <Route exact path="/app/orders" component={Orders} />
      </Switch>
    </Layout>
  );
};

export default withStyles(styles)(Application);
