import { Action } from "../index";
import { Reducer } from "redux";
import { OrderedMap } from "immutable";
import { TimeSlot } from "../../models/TimeSlot";
import { ActionType } from "typesafe-actions";

export type TimeSlotAction = ActionType<typeof import("./actions")>;
export class TimeSlotState {
  readonly list: OrderedMap<string, TimeSlot> = OrderedMap();
  readonly loading: boolean = false;
  readonly loadingFailed: boolean = false;
  readonly creating: boolean = false;
  readonly deleting: boolean = false;
}

type TimeSlotsReducer = Reducer<TimeSlotState, Action>;

export const timeslotsReducer: TimeSlotsReducer = function(state = new TimeSlotState(), action): TimeSlotState {
  switch (action.type) {
    case "TIMESLOTS/LIST":
      return {
        ...state,
        loading: true,
        loadingFailed: false
      };
    case "TIMESLOTS/LIST_SUCCESS": {
      const previousList = state.list;
      const sortedList = action.payload.sort((a, b) => (a.start < b.start ? -1 : 1));
      const list = previousList.mergeDeep(sortedList.map((timeslot: TimeSlot) => [timeslot.id, timeslot]));
      return {
        ...state,
        loading: false,
        list
      };
    }
    case "TIMESLOTS/LIST_FAILED":
      return {
        ...state,
        loading: false,
        loadingFailed: true
      };
    case "TIMESLOTS/CREATE":
      return {
        ...state,
        creating: true
      };
    case "TIMESLOTS/CREATE_SUCCESS": {
      const list = state.list.set(action.payload.id, action.payload).sort((a, b) => (a.start < b.start ? -1 : 1));
      return {
        ...state,
        creating: false,
        list
      };
    }
    case "TIMESLOTS/CREATE_FAILED": {
      return {
        ...state,
        creating: false
      };
    }
    case "TIMESLOTS/DELETE": {
      return {
        ...state,
        deleting: true
      };
    }
    case "TIMESLOTS/DELETE_SUCCESS": {
      const list = state.list.remove(action.payload);
      return {
        ...state,
        deleting: false,
        list
      };
    }
    case "TIMESLOTS/DELETE_FAILED": {
      return {
        ...state,
        deleting: false
      };
    }
    default:
      return state;
  }
};
