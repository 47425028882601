import React from "react";
import { FormikValues, FormikErrors, FormikTouched } from "formik";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { get } from "lodash";

type FormikFieldProps<T> = {
  values: FormikValues;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  handleBlur(e: React.FocusEvent<any>): void;
  handleBlur<T = string | any>(fieldOrEvent: T): T extends string ? ((e: any) => void) : void;
  handleChange(e: React.ChangeEvent<any>): void;
  handleChange<T = unknown | React.ChangeEvent<any>>(
    field: T
  ): T extends React.ChangeEvent<any> ? void : ((e: unknown | React.ChangeEvent<any>) => void);
}

type FormikTextFieldProps<T> = TextFieldProps & FormikFieldProps<T>

export const FormikTextField = <T extends any>(props: React.PropsWithChildren<FormikTextFieldProps<T>>) => {
  const { values, errors, touched, handleBlur, handleChange, ...rest } = props;
  const name = props.name || "";

  return (
    <TextField
      {...rest}
      value={get(values, name)}
      error={get(touched, name) && !!get(errors, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={(get(touched, name) && get(errors, name)) || rest.helperText}
    />
  );
};

