import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { initializeApplication } from "./store/application/thunks";
import { PrivateRoute } from "./components/PrivateRoute";

import { State } from "./store";
import ModalPresenter from "./views/ModalPresenter/ModalPresenter";
import { NotFound } from "./views/Errors";
import { AdminRoute } from "./components/AdminRoute";
import SnackBarPresenter from "./views/SnackBarPresenter/SnackBarPresenter";
import { Home } from "./views/Home";
import { Admin } from "./views/Admin";
import Signup from "./views/Home/Auth/Signup";
import SignIn from "./views/Home/Auth/SignIn";
import ValidateCode from "./views/Home/Auth/ValidateCode";
import Application from "./views/Application/Application";
import ForgotPassword from "./views/Home/Auth/ForgotPassword";
import ResetPassword from "./views/Home/Auth/ResetPassword";
import { PublicRoute } from "./components/PublicRoute";

interface AppProps extends RouteComponentProps {
  initialized: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  initializeApplication: () => void;
}

class App extends Component<AppProps> {
  componentDidMount() {
    this.props.initializeApplication();
  }

  render() {
    const { isAuthenticated, isAdmin, initialized } = this.props;

    return (
      initialized && (
        <React.Fragment>
          <ModalPresenter />
          <Switch>
            <PublicRoute isAuthenticated={isAuthenticated} exact path="/" component={Home} />
            <PublicRoute isAuthenticated={isAuthenticated} path="/signin" component={SignIn} />
            <PublicRoute isAuthenticated={isAuthenticated} path="/signup" component={Signup} />
            <PublicRoute isAuthenticated={isAuthenticated} path="/forgotPassword" component={ForgotPassword} />
            <PublicRoute isAuthenticated={isAuthenticated} path="/resetPassword" component={ResetPassword} />

            <PublicRoute isAuthenticated={isAuthenticated} path="/verifyEmail" component={ValidateCode} />

            <PrivateRoute isAuthenticated={isAuthenticated} path="/app" redirectPath="/signin" component={Application} />
            <AdminRoute isAdmin={isAdmin} path="/admin" redirectPath="/app/cart" component={Admin}></AdminRoute>

            <Route component={NotFound} />
          </Switch>
          <SnackBarPresenter />
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state: State) {
  const user = state.auth.currentUser;
  return {
    initialized: state.application.initialized,
    isAdmin: (user && user.isAdmin) || false,
    isAuthenticated: !!user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { initializeApplication }
  )(App)
);
