// file: /components/LinkButton.jsx
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

interface LinkButtonProps extends ButtonProps, RouteComponentProps {
  to: string;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = props => {
  const { history, location, match, staticContext, to, onClick, ...rest } = props;

  return (
    <Button
      {...rest}
      onClick={event => {
        onClick && onClick(event);
        history.push(to);
      }}
    />
  );
};

export default withRouter(LinkButton);
