import React from "react";
import { Typography, Paper, Grid, Button, Switch, createStyles, withStyles, Theme, FormControl, InputLabel, Select, FormHelperText } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../store";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { Product, ProductType, productTypes, productTypeDescription } from "../../../models/Product";
import { Payload } from "../../../models/Payload";
import { deleteProduct, createProduct, updateProduct } from "../../../store/products/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";

type ProductPayload = {
  name: string;
  description?: string;
  price: string;
  available: boolean;
  type: ProductType;
  flavors?: string;
  options?: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px"
      }
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    }
  });

interface FormikCreateProductProps extends FormikProps<ProductPayload>, ConnectedCreateProductProps, WithStyles<typeof styles> {}

class CreateProduct extends React.Component<FormikCreateProductProps> {
  deleteProduct = () => {
    const { deleteProduct, product, history } = this.props;
    if (!product) return;
    deleteProduct(product.id);
    history.push("/admin/products");
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, product, history } = this.props;
    const title = product ? product.name : "Nouveau Produit";
    const updating = !!product;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle } = this.props.classes;

    return (
      <div>
        <Grid className={sectionTitle} container justify="flex-end" alignItems="center" spacing={6}>
          <Grid item xs>
            <Typography align="left" variant="h2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField name="name" label="Nom" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="description" label="Description" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="price" label="Prix" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={touched.type && !!errors.type}>
                  <InputLabel shrink>Type</InputLabel>
                  <Select name="type" value={values.type} onChange={handleChange} onBlur={handleBlur} fullWidth native>
                    {productTypes.map((type: ProductType) => (
                      <option key={type} value={type}>
                        {productTypeDescription(type)}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>{touched.type && errors.type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="flavors" label="Sauces" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="options" label="Options" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography>Disponible</Typography>
                  </Grid>
                  <Grid item>
                    <Switch name="available" color="primary" defaultChecked={values.available} onChange={handleChange} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={5}>
                  {updating && (
                    <Grid item>
                      <Button variant="contained" onClick={this.deleteProduct}>
                        Delete
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button type="submit" variant="contained" color="secondary">
                      {updating ? "Update" : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </div>
    );
  }
}

const StyledCreateProduct = withStyles(styles)(CreateProduct);

const FormikCreateProduct = withFormik<ConnectedCreateProductProps, ProductPayload>({
  mapPropsToValues: props => {
    const { product } = props;
    if (product)
      return {
        ...product,
        price: product.price.toString(),
        flavors: product.flavors ? product.flavors.join(", ") : undefined,
        options: product.options ? product.options.join(", ") : undefined
      };
    return {
      name: "",
      type: "pizza",
      description: "",
      price: "",
      available: true,
      flavors: "",
      options: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, createProduct, updateProduct, product } = formikBag.props;
    const payload = {
      ...values,
      description: !!values.description ? values.description : undefined,
      price: parseFloat(values.price),
      flavors: values.flavors ? values.flavors.replace(" ", "").split(",") : undefined,
      options: values.options ? values.options.replace(" ", "").split(",") : undefined
    };
    if (product) {
      await updateProduct({ id: product.id, ...payload });
    } else {
      await createProduct(payload);
    }
    formikBag.setSubmitting(false);
    history.push("/admin/products");
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("Champ requis"),
    price: yup
      .number()
      .positive("Le prix doit être positif")
      .required("Champ requis"),
    type: yup.string().oneOf(productTypes, 'Valeurs possibles: ' + productTypes.toString())
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledCreateProduct);

interface OuterCreateProductProps extends RouteComponentProps<{ productId: string }> {}

interface ConnectedCreateProductProps extends OuterCreateProductProps {
  createProduct: (product: Payload<Product>) => void;
  updateProduct: (product: Product) => void;
  deleteProduct: (id: string) => void;
  product?: Product;
}

function mapStateToProps(state: State, ownProps: OuterCreateProductProps) {
  const id = ownProps.match.params.productId;
  return {
    products: state.products.list,
    product: id === "new" ? undefined : state.products.list.get(id),
    ...ownProps
  };
}

export default connect(
  mapStateToProps,
  { createProduct, deleteProduct, updateProduct }
)(FormikCreateProduct);
