import React, { FunctionComponent, EventHandler } from "react";
import { Map } from "immutable";
import { Typography, createStyles, withStyles, List, ListItem, Button } from "@material-ui/core";
import { Order } from "../../../models/Order";
import { Location } from "../../../models/Location";

import { Product } from "../../../models/Product";
import { WithStyles } from "@material-ui/styles";
import { palette } from "../../../css/theme";
import { showModal } from "../../../store/application/actions";
import { connect } from "react-redux";

import classNames from "classnames";
import { ModalName, Modal } from "../../../store/application/modal";
import { State } from "../../../store";

interface OrderItemProps extends WithStyles<typeof styles> {
  order: Order;
  location?: Location;
  enabled: boolean;
  showModal: (modal: Modal) => void;
  products: Map<string, Product>;
}

const styles = () =>
  createStyles({
    rootPaper: {
      width: "100%",
      padding: "12px",
      backgroundColor: palette.background
    },
    disabled: {
      backgroundColor: palette.gray1,
      opacity: 0.5
    },
    fulfilled: {
      color: "white",
      backgroundColor: palette.success,
      opacity: 0.5
    },
    delivery: {
      backgroundColor: "rgba(247, 76, 85, 0.3)"
    },
    buttonLabel: {
      textTransform: "capitalize",
      display: "block",
      textAlign: "left"
    },
    productOrder: {
      flexDirection: "column",
      alignItems: "flex-start"
    },
    productName: {
      fontSize: "18px",
      fontWeight: 600
    },
    productOption: {
      fontSize: "18px",
      fontWeight: 400
    }
  });

const OrderItemRaw: FunctionComponent<OrderItemProps> = props => {
  const { order, products, enabled, location } = props;
  const { rootPaper, disabled, buttonLabel, fulfilled, productOrder, productName, productOption, delivery } = props.classes;

  const onClick: EventHandler<React.MouseEvent> = event => {
    const { order, showModal } = props;
    showModal({
      id: ModalName.adminOrder,
      order
    });
  };
  const isFulfilled = enabled && order.fulfilled === true;
  const isDelivery = enabled && order.delivery && order.fulfilled === false;
  return (
    <ListItem disableGutters>
      <Button
        variant="contained"
        onClick={onClick}
        className={classNames(rootPaper, { [disabled]: !enabled, [fulfilled]: isFulfilled, [delivery]: isDelivery })}
        classes={{ label: buttonLabel }}
      >
        <List>
          {order.products.map((product, index) => {
            const selectedProduct = products.get(product.productId);
            if (!selectedProduct) return null;

            return (
              <ListItem className={productOrder} disableGutters key={index}>
                <Typography className={productName} variant="body1">
                  {selectedProduct.name}
                </Typography>
                {product.options && (
                  <Typography className={productOption} variant="body2">
                    {product.options}
                  </Typography>
                )}
                {product.flavor && (
                  <Typography className={productOption} variant="body2">
                    Sauce: {product.flavor}
                  </Typography>
                )}
              </ListItem>
            );
          })}
        </List>
        {location && (
          <React.Fragment>
            <Typography variant="body1">Livraison: {location.name}</Typography>
            <br />
          </React.Fragment>
        )}
        <Typography variant="subtitle2">Total: {order.total}€</Typography>
        <Typography variant="subtitle2">
          {order.userName}
          {order.phoneNumber ? ` - ${order.phoneNumber}` : ""}
        </Typography>
      </Button>
    </ListItem>
  );
};

function mapStateToProps(state: State, outerProps: { order: Order }) {
  const { delivery } = outerProps.order;
  const location = delivery ? state.locations.list.get(delivery) : undefined;
  return {
    location
  };
}

export const OrderItem = connect(
  mapStateToProps,
  { showModal }
)(withStyles(styles)(OrderItemRaw));
