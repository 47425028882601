import React, { FunctionComponent } from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Map, OrderedMap } from "immutable";
import { Paper, Typography, Grid, TableBody, Table, TableRow, TableCell, List, ListItem } from "@material-ui/core";
import { Order } from "../../../models/Order";
import { Product } from "../../../models/Product";
import { TimeSlot } from "../../../models/TimeSlot";
import { Location } from "../../../models/Location";

interface OrderListProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  orders: Map<string, Order>;
  products: Map<string, Product>
  timeslots: OrderedMap<string, TimeSlot>;
  locations: Map<string, Location>;
}

const styles = () =>
  createStyles({
    productOrder: {
        flexDirection: "column",
        alignItems: "flex-start"
      }
  });

const OrderListRaw: FunctionComponent<OrderListProps> = props => {
  const { orders, products, className, classes, locations, timeslots} = props;
  const {productOrder } = classes

  return (
    <Paper className={className} elevation={1}>
      <Table>
        <TableBody>
          {orders &&
            orders.sort((a, b) => b.createdAt - a.createdAt).entrySeq().map(([key, order]) => {
              const delivery = order.delivery ? locations.get(order.delivery) : undefined
              const deliveryName = delivery ? delivery.name : "camion"
              const timeslot = timeslots.get(order.timeslot)
              if (!timeslot) return null
              return (
                <TableRow key={key}>
                  <TableCell style={{ padding: "24px" }} padding="none">
                      <Grid container direction="column">
                        <Grid item>
                        <List>
                            {order.products.map((product, index) => {
                                const selectedProduct = products.get(product.productId);
                                if (!selectedProduct) return null;
                                return (
                                <ListItem className={productOrder} disableGutters key={index}>
                                    <Typography variant="body1">
                                    {selectedProduct.name}
                                    </Typography>
                                    {product.options && (
                                    <Typography variant="body2">
                                        {product.options}
                                    </Typography>
                                    )}
                                    {product.flavor && (
                                    <Typography variant="body2">
                                        Sauce: {product.flavor}
                                    </Typography>
                                    )}
                                </ListItem>
                                );
                            })}
                            </List>
                        </Grid>
                         <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            Commande à récupérer au {deliveryName} à {timeslot.end}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            Total {order.total}€
                          </Typography>
                        </Grid>
                      </Grid>
                
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const OrderList = withStyles(styles)(OrderListRaw)