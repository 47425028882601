import React from "react";
import { connect } from "react-redux";
import { State } from "../../store";
import { hideModal } from "../../store/application/actions";
import { ModalName, Modal } from "../../store/application/modal";
import { Map } from "immutable";
import OrderModal from "../Admin/Orders/OrderModal";
import CartItemModal from "../Application/Cart/CartItemModal";
import ResetOrders from "../Admin/Orders/ResetOrders";

interface ModalPresenterProps {
  modals: Map<ModalName, Modal>;
  hideModal: (id: ModalName) => void;
}

class ModalPresenter extends React.Component<ModalPresenterProps> {
  closeModal = (id: ModalName) => {
    this.props.hideModal(id);
  };

  renderModal = (modal: Modal) => {
    switch (modal.id) {
      case ModalName.adminOrder: {
        const { id, ...data } = modal;
        return <OrderModal open onClose={() => this.closeModal(modal.id)} {...data} />;
      }
      case ModalName.cartItem: {
        const { id, ...data } = modal;
        return <CartItemModal open onClose={() => this.closeModal(modal.id)} {...data} />;
      }
      case ModalName.resetOrders: {
        const { id, ...data } = modal;
        return <ResetOrders open onClose={() => this.closeModal(modal.id)} {...data} />;
      }
      default:
        const _exhaustiveMatch: never = modal;
        throw new Error("Non-exhausive match for modal: " + _exhaustiveMatch);
    }
  };

  render() {
    const { modals } = this.props;
    return (
      <React.Fragment>
        {modals.entrySeq().map(([name, modal]) => (
          <React.Fragment key={name}>{this.renderModal(modal)}</React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    modals: state.application.modals
  };
}

export default connect(
  mapStateToProps,
  { hideModal }
)(ModalPresenter);
