import React, { FunctionComponent, ReactEventHandler } from "react";
import { Button, DialogTitle, DialogContent, Typography, Grid, Fab } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { ModalData, ResetOrdersModal } from "../../../store/application/modal";
import { CloseRounded } from "@material-ui/icons";
import { palette } from "../../../css/theme";
import classNames from "classnames";

const styles = createStyles({
  root: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  title: {
    flexGrow: 1
  },
  cancelButton: {
    color: palette.white,
    backgroundColor: palette.warning
  },
  orderRecap: {
    paddingBottom: "24px"
  },
  button: {
    width: "100%",
    height: "80px"
  }
});

type Props = WithStyles<typeof styles> & Omit<DialogProps, "classes"> & ModalData<ResetOrdersModal> 

const ResetOrdersDialog: FunctionComponent<Props> = props => {
  const { classes, resetOrders, ...rest } = props;
  const { root, title, cancelButton, orderRecap, button } = classes;
  const onClick: ReactEventHandler = event => {
    const { onClose } = props;
    onClose && onClose(event, "escapeKeyDown");
  };

  const onReset: ReactEventHandler = event => {
    const { onClose } = props;
    resetOrders();
    onClose && onClose(event, "escapeKeyDown");
  };

  return (
    <Dialog classes={{ paper: root }} fullWidth open {...rest}>
      <DialogTitle>
        <Grid container alignItems="center" justify="flex-end">
          <Grid className={title} item>
            <Typography variant="h6">Réinitaliser la journée</Typography>
          </Grid>
          <Grid item>
            <Fab size="small" color="secondary" onClick={onClick}>
              <CloseRounded />
            </Fab>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="center" className={orderRecap}>
            Continuer entraînera la réinitialisation de la journée: la capacité des créneaux sera remis à la capacité initiale,
            mais les commandes ne seront pas supprimées. 
        </Typography>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={6}>
            <Button className={classNames(button, cancelButton)} variant="contained" onClick={onClick}>
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className={button} variant="contained" color="primary" onClick={onReset}>
              Confirmer la réinitialisation
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default (withStyles(styles)(ResetOrdersDialog));
