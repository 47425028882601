import { createMuiTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";
import "./index.css";

// See https://material-ui.com/customization/themes/

export const palette = {
  primaryBlue: "#23386A", // mainBlue

  secondaryBlue: "#087CA7", // cerulean

  lightSecondary: "#F1F0EA",
  primaryYellow: "#ECA72C",

  // Text colors
  darkTextColor: "#1d365b", // Space Cadet
  lightTextColor: "#7a8c99", // Light slate gray

  success: "#84B25C",
  warning: amber[700],
  danger: "#F78388",

  background: "#fefefe",
  backgroundDark: "#fffbf6", // Snow

  black: "#000000",
  white: "#ffffff",
  gray0: "#fafafa",
  gray1: "#f2f2f2",
  gray2: "#cccccc",
  gray3: "#e1dfdf",
  gray4: "#9b9b9b",
  gray5: "#979797",
  gray6: "#4a4a4a"
};

const adaptativeFont = (max: number, min: number) => {
  return `calc(${min}px + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300)))`;
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette.primaryBlue
    },
    secondary: {
      main: palette.success,
      contrastText: palette.white
    },
    text: {
      primary: palette.darkTextColor,
      secondary: palette.lightTextColor
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Helvetica Neue"',
      "Lato",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: adaptativeFont(48, 32),
        fontWeight: 700,
        marginBottom: "0.35em"
      },

      h2: {
        fontSize: adaptativeFont(37, 22),
        fontWeight: 600,
        marginBottom: "0.35em"
      },

      h3: {
        fontSize: "19px",
        fontWeight: 600,
        marginBottom: "0.35em"
      },
      subtitle2: {
        color: palette.gray5,
        fontWeight: 400
      },
      body2: {
        color: palette.gray6
      }
    },
    MuiAppBar: {
      root: {
        "box-shadow": "0px 1px 3px 0px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.06), 0px 2px 1px -1px rgba(0,0,0,0.03)"
      }
    },
    MuiButton: {}
  }
});
