import { ThunkResult } from "..";
import * as actions from "./actions";
import { listProducts } from "../products/thunks";
import { listTimeSlots } from "../timeslots/thunks";
import { listLocations } from "../locations/thunks";
import { getOrdersEnabled } from "../orders/thunks";
import { getUser } from "../auth/thunks";
import { getInfos } from "../infos/thunks";

export function initializeApplication(): ThunkResult<void> {
  return async function (dispatch) {
    try {
      dispatch(actions.initializingApp());
      await Promise.all([
        dispatch(getUser()),
        dispatch(getOrdersEnabled()),
        dispatch(getInfos()),
        dispatch(listProducts()),
        dispatch(listTimeSlots()),
        dispatch(listLocations()),
      ]);
      dispatch(actions.initializedApp());
    } catch (error) {
      console.error(error);
    }
  };
}
