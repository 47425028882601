import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Theme,
  withStyles
} from "@material-ui/core";
import { Product, productTypeDescription } from "../../../models/Product";
import { AddCircleRounded, RemoveCircleRounded } from "@material-ui/icons";
import { WithStyles, createStyles } from "@material-ui/styles";
import { palette } from "../../../css/theme";
import { connect } from "react-redux";
import { showModal } from "../../../store/application/actions";

import { removeItem } from "../../../store/cart/actions";
import { addItems } from "../../../store/cart/thunks";
import { State } from "../../../store";
import { ProductOrder } from "../../../models/Order";
import { Modal, ModalName, CartItemModal } from "../../../store/application/modal";

interface CartProductListProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  products: Map<string, Product>;
  cart: Map<string, ProductOrder>;
  addItems: (product: ProductOrder[]) => void;
  removeItem: (id: string) => void;
  showModal: (modal: Modal) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    quantityBadge: {
      fontWeight: 700,
      backgroundColor: palette.success,
      padding: theme.spacing(1),
      color: "white",
      position: "absolute",
      top: 0,
      right: 0
    },
    controlGrid: {
      paddingRight: "12px"
    },
    controlCell: {
      position: "relative"
    },
    minusButton: {
      color: palette.gray4
    },
    plusButton: {
      "& svg": {
        fontSize: 40
      }
    }
  });

const CartProductListRaw: FunctionComponent<CartProductListProps> = props => {
  const { products, className, classes, cart, addItems } = props;
  const { quantityBadge, minusButton, plusButton, controlCell, controlGrid } = classes;
  const pizzas = products.filter(product => product.type === "pizza" && product.available).sortBy(product => product.name);
  const kebabs = products.filter(product => product.type === "kebab" && product.available).sortBy(product => product.name);
  const chickenWing = products
    .filter(product => product.type === "chickenWing" && product.available)
    .sortBy(product => product.name);
  const beverages = products.filter(product => product.type === "beverage" && product.available).sortBy(product => product.name)

  const addItem = (product: Product) => {
    if (product.type === "pizza" || product.type === "beverage") {
      addItems([{ productId: product.id, productType: product.type }]);
    } else {
      const modal: CartItemModal = { id: ModalName.cartItem, product, addItems };
      props.showModal(modal);
    }
  };

  const removeItem = (product: Product) => {
    const itemKey = cart.findKey(productOrder => productOrder.productId === product.id);
    itemKey && props.removeItem(itemKey);
  };


  return (
    <Table className={className}>
      {[pizzas, kebabs, chickenWing, beverages].map(category => {
        const firstItem: Product | undefined = category.first(undefined);
        if (!firstItem) return null;

        return (
          <React.Fragment key={firstItem.type}>
            <TableHead>
              <TableRow>
                <TableCell>{productTypeDescription(firstItem.type)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {category.entrySeq().map(([key, product]) => {
                const quantity = cart.filter(product => product.productId === key).count();

                return (
                  <TableRow key={key}>
                    <TableCell style={{ padding: "24px" }} padding="none">
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1">{product.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            {product.description}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">{product.price}€</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell className={controlCell}>
                      {quantity > 0 && (
                        <Typography className={quantityBadge} variant="body2">
                          {quantity}
                        </Typography>
                      )}
                      <Grid className={controlGrid} container justify="flex-end" alignItems="center" wrap="nowrap">
                        {quantity > 0 && (
                          <Grid item>
                            <IconButton className={minusButton} size="small" color="inherit" onClick={() => removeItem(product)}>
                              <RemoveCircleRounded />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item>
                          <IconButton className={plusButton} size="small" color="primary" onClick={() => addItem(product)}>
                            <AddCircleRounded />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </React.Fragment>
        );
      })}
    </Table>
  );
};

function mapStateToProps(state: State) {
  return {
    cart: state.cart.products
  };
}

export const CartProductList = connect(
  mapStateToProps,
  { addItems, removeItem, showModal }
)(withStyles(styles)(CartProductListRaw));
