import { createStore, combineReducers, applyMiddleware, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkMiddleware, ThunkAction } from "redux-thunk";

import { ApplicationState, applicationReducer, ApplicationAction } from "./application/reducer";
import { ProductAction, productsReducer, ProductState } from "./products/reducer";
import { TimeSlotState, TimeSlotAction, timeslotsReducer } from "./timeslots/reducer";
import { OrderState, ordersReducer, OrderAction } from "./orders/reducer";
import { LocationState, locationsReducer, LocationAction } from "./locations/reducer";
import { AuthState, AuthAction, authReducer } from "./auth/reducer";
import { CartState, cartReducer, CartAction } from "./cart/reducer";
import { InfosState, InfosActions, infosReducer } from "./infos/reducer";

export type State = {
  readonly application: ApplicationState;
  readonly products: ProductState;
  readonly timeslots: TimeSlotState;
  readonly orders: OrderState;
  readonly locations: LocationState;
  readonly auth: AuthState;
  readonly cart: CartState;
  readonly infos: InfosState;
};

export type Action =
  | ApplicationAction
  | ProductAction
  | TimeSlotAction
  | OrderAction
  | LocationAction
  | AuthAction
  | CartAction
  | InfosActions;

export type ThunkResult<R> = ThunkAction<R, State, undefined, Action>;

const reducers = combineReducers<State, Action>({
  application: applicationReducer,
  products: productsReducer,
  timeslots: timeslotsReducer,
  orders: ordersReducer,
  locations: locationsReducer,
  auth: authReducer,
  cart: cartReducer,
  infos: infosReducer,
});

// when user logout, we reset the whole store, except the application state
const rootReducer: Reducer<State, Action> = (state, action) => {
  if (action.type === "AUTH/SIGNOUT" && state) {
    state = {
      ...state,
      application: state ? state.application : new ApplicationState(),
      auth: new AuthState(),
      orders: new OrderState(),
    };
  }
  return reducers(state, action);
};

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<State, Action>)));
