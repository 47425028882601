import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import { Paper, Typography, Grid, TableBody, Table, TableRow, TableCell, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Product, productTypeDescription } from "../../../models/Product";

interface ProductListProps extends React.HTMLAttributes<HTMLDivElement> {
  products: Map<string, Product>;
}

export const ProductList: FunctionComponent<ProductListProps> = props => {
  const { products, className } = props;

  return (
    <Paper className={className} elevation={1}>
      <Table>
        <TableBody>
          {products &&
            products.sort((a, b) => a.name < b.name ? -1 : 1).entrySeq().map(([key, product]) => {
              return (
                <TableRow key={key}>
                  <TableCell style={{ padding: "24px" }} padding="none">
                    <Link color="textPrimary" component={RouterLink} to={`products/${key}`}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1">{product.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            {product.description}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            {productTypeDescription(product.type)} - {product.price}€
                          </Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};
