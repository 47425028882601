import React, { ReactNode, Component } from "react";
import MUAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import { palette } from "../css/theme";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

interface AppBarProps extends WithStyles<typeof styles>, React.HTMLAttributes<HTMLDivElement> {
  rightButton: ReactNode;
  leftButton: ReactNode;
}

interface AppBarState {
  notScrolled: boolean;
}

const styles = () => {
  return createStyles({
    root: {
      backgroundColor: palette.gray0,
      transition: "box-shadow 0.3s ease-in-out"
    },
    noShadow: {
      boxShadow: "none"
    }
  });
};

class AppBar extends Component<AppBarProps, AppBarState> {
  state = {
    notScrolled: window.scrollY === 0
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ notScrolled: window.scrollY === 0 });
  };

  render() {
    const { classes, children, leftButton, rightButton, className } = this.props;
    const { notScrolled } = this.state;
    const rootClass = classNames(className, classes.root, {
      [classes.noShadow]: notScrolled
    });
    return (
      <MUAppBar className={rootClass} position="fixed" elevation={1}>
        <Toolbar>
          <Grid alignItems="center" justify="center" container>
            {leftButton && (
              <Grid item xs={3}>
                {leftButton}
              </Grid>
            )}
            <Grid item xs={leftButton ? 6 : 9}>
              {children}
            </Grid>
            <Grid item xs={3}>
              {rightButton}
            </Grid>
          </Grid>
        </Toolbar>
      </MUAppBar>
    );
  }
}

export default withStyles(styles)(AppBar);
