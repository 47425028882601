import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../store";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { Location } from "../../../models/Location";
import { Payload } from "../../../models/Payload";
import { deleteLocation, createLocation, updateLocation } from "../../../store/locations/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";

type LocationPayload = {
  name: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px"
      }
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    }
  });

interface FormikCreateLocationProps
  extends FormikProps<LocationPayload>,
    ConnectedCreateLocationProps,
    WithStyles<typeof styles> {}

class CreateLocation extends React.Component<FormikCreateLocationProps> {
  deleteLocation = () => {
    const { deleteLocation, deliveryLocation, history } = this.props;
    if (!deliveryLocation) return;
    deleteLocation(deliveryLocation.id);
    history.push("/admin/locations");
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, deliveryLocation, history, isSubmitting } = this.props;
    const title = deliveryLocation ? deliveryLocation.name : "Nouveau Lieu";
    const updating = !!deliveryLocation;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle } = this.props.classes;

    return (
      <div>
        <Grid className={sectionTitle} container justify="flex-end" alignItems="center" spacing={6}>
          <Grid item xs>
            <Typography align="left" variant="h2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField name="name" label="Nom" fullWidth {...fieldProps} />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={5}>
                  {updating && (
                    <Grid item>
                      <Button variant="contained" onClick={this.deleteLocation}>
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button disabled={isSubmitting} type="submit" variant="contained" color="secondary">
                      {updating ? "Update" : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </div>
    );
  }
}

const StyledCreateLocation = withStyles(styles)(CreateLocation);

const FormikCreateLocation = withFormik<ConnectedCreateLocationProps, LocationPayload>({
  mapPropsToValues: props => {
    const { deliveryLocation } = props;
    if (deliveryLocation) return deliveryLocation;
    return {
      name: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, createLocation, updateLocation, deliveryLocation } = formikBag.props;
    if (deliveryLocation) {
      await updateLocation({ id: deliveryLocation.id, ...values });
    } else {
      await createLocation(values);
    }
    formikBag.setSubmitting(false);
    history.push("/admin/locations");
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("Champ requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledCreateLocation);

interface OuterCreateLocationProps extends RouteComponentProps<{ locationId: string }> {}

interface ConnectedCreateLocationProps extends OuterCreateLocationProps {
  createLocation: (location: Payload<Location>) => void;
  updateLocation: (location: Location) => void;
  deleteLocation: (id: string) => void;
  deliveryLocation?: Location;
}

function mapStateToProps(state: State, ownProps: OuterCreateLocationProps) {
  const id = ownProps.match.params.locationId;
  return {
    locations: state.locations.list,
    deliveryLocation: id === "new" ? undefined : state.locations.list.get(id),
    ...ownProps
  };
}

export default connect(
  mapStateToProps,
  { createLocation, deleteLocation, updateLocation }
)(FormikCreateLocation);
