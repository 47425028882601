import React, { FunctionComponent } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { ProductState } from "../../../store/products/reducer";
import { Fab, Grid } from "@material-ui/core";
import { CartProductList } from "./CartProductList";
import { ShoppingBasketRounded } from "@material-ui/icons";
import { CartState } from "../../../store/cart/reducer";
import { withRouter, RouteComponentProps, Redirect } from "react-router";

interface CartProps extends WithStyles<typeof styles>, RouteComponentProps<{ timeslotId?: string }> {
  products: ProductState;
  cart: CartState;
  total: number;
  enabled: boolean;
  adminMode: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    fab: {
      top: "auto",
      bottom: 10,
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "fixed"
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    sectionTitle: {
      marginBottom: "32px"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const CartRaw: FunctionComponent<CartProps> = props => {
  const { total, enabled, adminMode, match } = props;
  const { loading, list } = props.products;
  const { listContainer, fab, extendedIcon } = props.classes;

  if (!enabled) {
    return <Redirect to="/app/infos"></Redirect>
    
  }
  return (
    <React.Fragment>
      <div className={listContainer}>
        {loading && "Chargement des produits"}
        {!loading && !!list && list.size === 0 && "Aucun produits"}
        {!loading && !!list && list.size > 0 && <CartProductList products={list} />}
      </div>
      {total > 0 && (
        <Fab
          className={fab}
          color="secondary"
          size="large"
          variant="extended"
          onClick={() => {
            const timeslot = match.params.timeslotId;
            const route = adminMode && !!timeslot ? `/admin/orders/${timeslot}/review` : "/app/cart/review";
            props.history.push(route);
          }}
        >
          <Grid container alignItems="center" justify="flex-start" wrap="nowrap" spacing={2}>
            <Grid item xs={2} style={{ height: "42px" }}>
              <ShoppingBasketRounded className={extendedIcon} />
            </Grid>
            <Grid item xs={8}>
              <span style={{ whiteSpace: "nowrap" }}> Afficher la commande </span>
            </Grid>
            <Grid item xs={2}>
              <span style={{ fontWeight: 700 }}>{`${total}€`}</span>
            </Grid>
          </Grid>
        </Fab>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state: State) {
  const total = state.cart.products.reduce((total, product) => {
    const productInfos = state.products.list.get(product.productId);
    if (!productInfos) return total;
    return total + productInfos.price;
  }, 0);
  return {
    products: state.products,
    cart: state.cart,
    enabled: state.orders.enabled,
    total
  };
}

export const Cart = withRouter(withStyles(styles)(connect(mapStateToProps, {})(CartRaw)));
