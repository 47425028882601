import * as React from "react";
import { RouteProps } from "react-router-dom";
import { ConditionalRoute } from "./ConditionalRoute";

interface PublicRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const { isAuthenticated, ...rest } = props;
  return <ConditionalRoute {...rest} condition={!isAuthenticated} redirectPath="/app/cart" />;
};
