import React, { FunctionComponent, ReactEventHandler } from "react";
import { Button, DialogTitle, DialogContent, Typography, Grid, Fab } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { deleteOrder, updateOrder } from "../../../store/orders/thunks";
import { ModalData, AdminOrderModal } from "../../../store/application/modal";
import { CloseRounded } from "@material-ui/icons";
import { palette } from "../../../css/theme";
import classNames from "classnames";
import { connect } from "react-redux";
import { Order } from "../../../models/Order";

const styles = createStyles({
  root: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  title: {
    flexGrow: 1
  },
  cancelButton: {
    color: palette.white,
    backgroundColor: palette.warning
  },
  orderRecap: {
    paddingBottom: "24px"
  },
  button: {
    width: "100%",
    height: "80px"
  }
});

interface Props extends WithStyles<typeof styles>, Omit<DialogProps, "classes">, ModalData<AdminOrderModal> {
  deleteOrder: (order: Order) => void;
  updateOrder: (order: Order, fulfilled: boolean) => void;
}

const AdminOrderModalDialog: FunctionComponent<Props> = props => {
  const { classes, order, deleteOrder, updateOrder, ...rest } = props;
  const { root, title, cancelButton, orderRecap, button } = classes;
  const onClick: ReactEventHandler = event => {
    const { onClose } = props;
    onClose && onClose(event, "escapeKeyDown");
  };

  const onDelete: ReactEventHandler = event => {
    const { deleteOrder, order, onClose } = props;
    deleteOrder(order);
    onClose && onClose(event, "escapeKeyDown");
  };

  const onUpdate: ReactEventHandler = event => {
    const { updateOrder, order, onClose } = props;
    updateOrder(order, true);
    onClose && onClose(event, "escapeKeyDown");
  };

  return (
    <Dialog classes={{ paper: root }} fullWidth open {...rest}>
      <DialogTitle>
        <Grid container alignItems="center" justify="flex-end">
          <Grid className={title} item>
            <Typography variant="h6">Gérer la commande</Typography>
          </Grid>
          <Grid item>
            <Fab size="small" color="secondary" onClick={onClick}>
              <CloseRounded />
            </Fab>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="center" className={orderRecap}>
          {order.userName} - {order.phoneNumber}
          <br />
          <span> {order.total} € </span>
        </Typography>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={6}>
            <Button className={classNames(button, cancelButton)} variant="contained" onClick={onDelete}>
              Annuler la commande
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className={button} variant="contained" color="primary" onClick={onUpdate}>
              Commande récupérée
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  null,
  { deleteOrder, updateOrder }
)(withStyles(styles)(AdminOrderModalDialog));
