import React from "react";
import { Typography, Grid, Button, createStyles, withStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../store";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { createInfos } from "../../../store/infos/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import { InfosState } from "../../../store/infos/reducer";

type InfosPayload = {
  content: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px"
      }
    },
    fields: {
      width: theme.breakpoints.width("md"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    messageContainer: {
        backgroundColor: "#FAFAFA",
        minHeight: "250px",
        marginTop: "24px",
        padding: "24px",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      },
  });

interface FormikUpdateInfosProps
  extends FormikProps<InfosPayload>,
    ConnectedUpdateInfosProps,
    WithStyles<typeof styles> {}

class UpdateInfos extends React.Component<FormikUpdateInfosProps> {
 

  render() {
    const { values, errors, handleChange, touched, handleBlur, history, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched, multiline: true };
    const { fields, sectionTitle, messageContainer } = this.props.classes;

    return (
      <div>
        <Grid className={sectionTitle} container justify="flex-end" alignItems="center" spacing={6}>
          <Grid item xs>
            <Typography align="left" variant="h2">
              Mise à jour du message d'accueil
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField  name="content" variant="outlined" fullWidth {...fieldProps} rows={24} />
              </Grid>
            

            <Grid xs={12}>
                <div className={messageContainer} dangerouslySetInnerHTML={{ __html: values.content }} />
                </Grid>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={5}>
                  
                  <Grid item>
                    <Button disabled={isSubmitting} type="submit" variant="contained" color="secondary">
                       Valider
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
      </div>
    );
  }
}

const StyledUpdateInfos = withStyles(styles)(UpdateInfos);

const FormikUpdateInfos = withFormik<ConnectedUpdateInfosProps, InfosPayload>({
  mapPropsToValues: props => {
    return {
      content: props.infos.content || ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, createInfos } = formikBag.props;
    await createInfos(values.content);
    formikBag.setSubmitting(false);
    history.push("/admin/infos");
  },
  validationSchema: yup.object().shape({
    content: yup.string().required("Champ requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledUpdateInfos);

interface OuterUpdateInfosProps extends RouteComponentProps<{}> {}

interface ConnectedUpdateInfosProps extends OuterUpdateInfosProps {
  createInfos: (content: string) => void;
  infos: InfosState;
}

function mapStateToProps(state: State, ownProps: OuterUpdateInfosProps) {
  return {
    infos: state.infos,
    ...ownProps
  };
}

export default connect(
  mapStateToProps,
  { createInfos }
)(FormikUpdateInfos);
