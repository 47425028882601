export type ProductType = "pizza" | "kebab" | "chickenWing" | "beverage";
export const productTypes: Array<ProductType> = ["pizza", "kebab", "chickenWing", "beverage"]
export interface Product {
  id: string;
  name: string;
  description?: string;
  type: ProductType;
  price: number;
  available: boolean;
  options?: string[];
  flavors?: string[];
}


export const productTypeDescription = (type: ProductType) => {
  switch (type) {
    case "pizza": return "Pizzas"
    case "kebab": return "Kebab"
    case "chickenWing": return "Chicken Wings"
    case "beverage": return "Boissons"
  }
}