import { Order, ProductOrder } from "../../models/Order";
import { Product } from "../../models/Product";

export enum ModalName {
  resetOrders,
  adminOrder,
  cartItem,
}

export type Modal = ResetOrdersModal | AdminOrderModal | CartItemModal;
export type ModalData<T> = Pick<T, Exclude<keyof T, "id">>;

export interface AdminOrderModal {
  id: ModalName.adminOrder;
  order: Order;
}

export interface ResetOrdersModal {
  id: ModalName.resetOrders;
  resetOrders: () => void;
}
export interface CartItemModal {
  id: ModalName.cartItem;
  product: Product;
  addItems: (product: ProductOrder[]) => void;
}
