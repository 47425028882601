import * as React from "react";
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";

interface ConditionalRouteProps extends RouteProps {
  condition: boolean;
  redirectPath: string;
}

export class ConditionalRoute extends React.Component<ConditionalRouteProps> {
  render() {
    const {
      component: Component,
      condition,
      redirectPath,
      ...rest
    } = this.props;
    return <Route {...rest} render={this.nestedRender} />;
  }

  private nestedRender = (renderProps: RouteComponentProps<any>) => {
    if (this.props.condition) {
      const { component: Component } = this.props;
      if (!Component) {
        return null;
      }
      return <Component {...renderProps} />;
    }

    return <Redirect to={this.props.redirectPath} />;
  };
}
