import { ThunkResult } from "..";
import { API } from "../../utils/API";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import { Payload } from "../../models/Payload";
import * as actions from "./actions";
import * as cartActions from "../cart/actions";
import { Order, ProductOrder } from "../../models/Order";
import { getCurrentDay } from "../../utils/dayIndex";
import moment from "moment";
import { listTimeSlots } from "../timeslots/thunks";

export function listDailyOrders(): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.listDailyOrders());
    try {
      const api = new API();
      const day = getCurrentDay();
      const response = await api.listOrders(day);
      const action = actions.listDailyOrdersSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.listDailyOrdersFailed(error));
    }
  };
}

export function listUserOrders(): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.listUserOrders());
    try {
      const api = new API();
      const response = await api.listUserOrders();
      const action = actions.listUserOrdersSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.listUserOrdersFailed(error));
    }
  };
}

export function createOrder(
  products: ProductOrder[],
  total: number,
  timeslot: string,
  delivery?: string,
  anonymous: boolean = false
): ThunkResult<Promise<boolean>> {
  return async function(dispatch, getState) {
    dispatch(actions.create());
    try {
      const state = getState();
      const user = state.auth.currentUser;
      if (!user) throw Error("Not authenticated");

      const order: Payload<Order> = {
        day: getCurrentDay(),
        timeslot,
        delivery,
        products,
        phoneNumber: anonymous ? "" : user.phoneNumber,
        userId: anonymous ? "anonymousUserId" : user.id,
        userName: anonymous ? "Commande au camion" : user.firstName,
        fulfilled: false,
        total
      };
      if (products.length === 0) throw Error("Aucuns produits sélectionnés");

      const api = new API();
      const response = await api.createOrder(order);
      dispatch(showFlash("Commande créé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
      dispatch(cartActions.clear());
      dispatch(listTimeSlots());
      return true;
    } catch (error) {
      console.error(error);
      const message = error.errorMessage || error.message || error;
      dispatch(showFlash(message, FlashMessageType.error));
      dispatch(actions.createFailed(error));
      return false;
    }
  };
}

export function updateOrder(order: Order, fulfilled: boolean): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      order.fulfilled = fulfilled;
      const response = await api.editOrder(order);
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de l'édition de la commande" + error, FlashMessageType.error));
      dispatch(actions.createFailed(error));
    }
  };
}

export function deleteOrder(order: Order): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.remove());
    try {
      const api = new API();
      await api.deleteOrder(order);
      dispatch(showFlash("Commande supprimé", FlashMessageType.success));
      dispatch(actions.removeSuccess(order.id));
      dispatch(listTimeSlots());
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la suppression de la commande" + error, FlashMessageType.error));
      dispatch(actions.removeFailed(error));
    }
  };
}

export function getOrdersEnabled(): ThunkResult<void> {
  return async function(dispatch) {
    const api = new API();
    const { enabled, delivery } = await api.ordersEnabled();
    dispatch(actions.enableOrders(enabled, delivery));
  };
}

export function setOrdersEnabled(enabled: boolean): ThunkResult<void> {
  return async function(dispatch, getState) {
    try {
      const api = new API();
      if (enabled) {
        await api.startDay();
      } else {
        const time = moment().format("HH:mm");
        const lastSlot = getState()
          .timeslots.list.filter(slot => slot.available)
          .last(undefined);
        if (lastSlot && time < lastSlot.end) throw Error("Le dernier créneau de la soirée n'est pas terminé.");
        await api.stopDay();
      }
      const delivery = getState().orders.delivery;
      dispatch(actions.enableOrders(enabled, delivery));
    } catch (error) {
      console.error(error.message);
      dispatch(showFlash("Erreur lors de l'arrêt des commandes: " + error.message, FlashMessageType.error));
    }
  };
}

export function setDeliveryEnabled(deliveryEnabled: boolean): ThunkResult<void> {
  return async function(dispatch, getState) {
    try {
      const api = new API();
      const { enabled, delivery } = await api.setDeliveryEnabled(deliveryEnabled);
      dispatch(actions.enableOrders(enabled, delivery));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur " + error, FlashMessageType.error));
    }
  };
}
