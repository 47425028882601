import React from "react";
import { Typography, Paper, Grid, Button, Switch, createStyles, withStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../store";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { TimeSlot, SlotCapacity } from "../../../models/TimeSlot";
import { Payload } from "../../../models/Payload";
import { deleteTimeSlot, createTimeSlot, updateTimeSlot } from "../../../store/timeslots/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";

type TimeSlotPayload = {
  start: string;
  end: string;
  capacity: Record<SlotCapacity, string>;
  available: boolean;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px"
      }
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    }
  });

interface FormikCreateTimeSlotProps
  extends FormikProps<TimeSlotPayload>,
    ConnectedCreateTimeSlotProps,
    WithStyles<typeof styles> {}

class CreateTimeSlot extends React.Component<FormikCreateTimeSlotProps> {
  deleteTimeSlot = () => {
    const { deleteTimeSlot, timeslot, history } = this.props;
    if (!timeslot) return;
    deleteTimeSlot(timeslot.id);
    history.push("/admin/timeslots");
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, timeslot, history } = this.props;
    const title = timeslot ? timeslot.id : "Nouveau Créneau";
    const updating = !!timeslot;
    const fieldProps = { values, errors, handleBlur, handleChange, touched, fullWidth: true };
    const { fields, sectionTitle } = this.props.classes;

    return (
      <div>
        <Grid className={sectionTitle} container justify="flex-end" alignItems="center" spacing={6}>
          <Grid item xs>
            <Typography align="left" variant="h2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={history.goBack}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper className={""}>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField type="time" name="start" label="Début" {...fieldProps} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField type="time" name="end" label="Fin" {...fieldProps} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="capacity.pizza" label="Nombre max de pizzas" {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="capacity.kebab" label="Nombre max de kebabs" {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="capacity.chickenWing" label="Nombre max de chicken wings" {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="capacity.beverage" label="Nombre max de boissons" {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="capacity.delivery" label="Nombre max de livraisons" {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography>Disponible</Typography>
                  </Grid>
                  <Grid item>
                    <Switch name="available" color="primary" defaultChecked={values.available} onChange={handleChange} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={5}>
                  {updating && (
                    <Grid item>
                      <Button variant="contained" onClick={this.deleteTimeSlot}>
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button type="submit" variant="contained" color="secondary">
                      {updating ? "Update" : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </div>
    );
  }
}

const StyledCreateTimeSlot = withStyles(styles)(CreateTimeSlot);

const FormikCreateTimeSlot = withFormik<ConnectedCreateTimeSlotProps, TimeSlotPayload>({
  mapPropsToValues: props => {
    const { timeslot } = props;
    if (timeslot) {
      return {
        ...timeslot,
        capacity: {
          pizza: timeslot.capacity.pizza.toString(),
          kebab: timeslot.capacity.kebab.toString(),
          chickenWing: timeslot.capacity.chickenWing.toString(),
          beverage: timeslot.capacity.beverage.toString(),
        delivery: timeslot.capacity.delivery.toString()
        }
      };
    }
    return {
      start: "",
      end: "",
      capacity: { pizza: "10", kebab: "4", chickenWing: "3", beverage: "100", delivery: "2" },
      available: true
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, createTimeSlot, updateTimeSlot, timeslot } = formikBag.props;
    const payload = {
      ...values,
      capacity: {
        pizza: parseInt(values.capacity.pizza),
        kebab: parseInt(values.capacity.kebab),
        chickenWing: parseInt(values.capacity.chickenWing),
        beverage: parseInt(values.capacity.beverage),
        delivery: parseInt(values.capacity.delivery)
      }
    };
    if (timeslot) {
      await updateTimeSlot({ id: timeslot.id, ...payload, remaining: payload.capacity });
    } else {
      await createTimeSlot({ ...payload, remaining: payload.capacity });
    }
    formikBag.setSubmitting(false);
    history.push("/admin/timeslots");
  },
  validationSchema: yup.object().shape({
    start: yup.string().required("Champ requis"),
    end: yup.string().required("Champ requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledCreateTimeSlot);

interface OuterCreateTimeSlotProps extends RouteComponentProps<{ timeslotId: string }> {}

interface ConnectedCreateTimeSlotProps extends OuterCreateTimeSlotProps {
  createTimeSlot: (timeslot: Payload<TimeSlot>) => void;
  updateTimeSlot: (timeslot: TimeSlot) => void;
  deleteTimeSlot: (id: string) => void;
  timeslot?: TimeSlot;
}

function mapStateToProps(state: State, ownProps: OuterCreateTimeSlotProps) {
  const id = ownProps.match.params.timeslotId;
  return {
    timeslots: state.timeslots.list,
    timeslot: id === "new" ? undefined : state.timeslots.list.get(id),
    ...ownProps
  };
}

export default connect(
  mapStateToProps,
  { createTimeSlot, deleteTimeSlot, updateTimeSlot }
)(FormikCreateTimeSlot);
