import { Action } from "../index";
import { Reducer } from "redux";
import { User } from "../../models/User";
import { ActionType } from "typesafe-actions";

export type AuthAction = ActionType<typeof import("./actions")>;
export class AuthState {
  readonly password?: string = undefined;
  readonly currentUser?: User = undefined;
  readonly authenticating: boolean = false;
  readonly authError?: Error = undefined;
}

type UsersReducer = Reducer<AuthState, Action>;

export const authReducer: UsersReducer = function(state = new AuthState(), action): AuthState {
  switch (action.type) {
    case "AUTH/SIGNUP_SUCCESS":
      return {
        ...state,
        authenticating: false,
        password: action.payload.password
      };
    case "AUTH/CONFIRM_EMAIL":
    case "AUTH/SIGNUP":
    case "AUTH/SIGNIN":
      return {
        ...state,
        authenticating: true,
        authError: undefined
      };
    case "AUTH/CONFIRM_EMAIL_SUCCESS":
    case "AUTH/SIGNIN_SUCCESS": {
      return {
        ...state,
        authenticating: false,
        password: undefined,
        currentUser: action.payload
      };
    }
    case "AUTH/CONFIRM_EMAIL_FAILED":
    case "AUTH/SIGNUP_FAILED":
    case "AUTH/SIGNIN_FAILED": {
      return {
        ...state,
        authenticating: false,
        authError: action.payload
      };
    }
    default:
      return state;
  }
};
