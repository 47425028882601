import { TimeSlot } from "../../models/TimeSlot";
import { createAction } from "typesafe-actions";

export const list = createAction("TIMESLOTS/LIST");
export const listSuccess = createAction("TIMESLOTS/LIST_SUCCESS", action => {
  return (timeslots: TimeSlot[]) => action(timeslots);
});
export const listFailed = createAction("TIMESLOTS/LIST_FAILED", action => {
  return (error: Error) => action(error);
});

export const create = createAction("TIMESLOTS/CREATE");
export const createSuccess = createAction("TIMESLOTS/CREATE_SUCCESS", action => {
  return (timeslot: TimeSlot) => action(timeslot);
});
export const createFailed = createAction("TIMESLOTS/CREATE_FAILED", action => {
  return (error: Error) => action(error);
});

export const remove = createAction("TIMESLOTS/DELETE");
export const removeSuccess = createAction("TIMESLOTS/DELETE_SUCCESS", action => {
  return (id: string) => action(id);
});
export const removeFailed = createAction("TIMESLOTS/DELETE_FAILED", action => {
  return (error: Error) => action(error);
});
