import { ThunkResult } from "..";
import { API } from "../../utils/API";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import { Payload } from "../../models/Payload";
import * as actions from "./actions";
import { TimeSlot } from "../../models/TimeSlot";

export function listTimeSlots(): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.list());
    try {
      const api = new API();
      const response = await api.listTimeSlots();
      const action = actions.listSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.listFailed(error));
    }
  };
}

export function createTimeSlot(timeslot: Payload<TimeSlot>): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.createTimeSlot(timeslot);
      dispatch(showFlash("Créneau créé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la création du créneau" + error, FlashMessageType.error));
      dispatch(actions.createFailed(error));
    }
  };
}

export function updateTimeSlot(timeslot: TimeSlot): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.updateTimeSlot(timeslot);
      dispatch(showFlash("Créneau sauvegardé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      showFlash("Erreur lors de la sauvegarde du créneau" + error, FlashMessageType.error);
      dispatch(actions.createFailed(error));
    }
  };
}

export function deleteTimeSlot(id: string): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.remove());
    try {
      const api = new API();
      await api.deleteTimeSlot(id);
      dispatch(showFlash("Créneau supprimé", FlashMessageType.success));
      dispatch(actions.removeSuccess(id));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la suppression du créneau" + error, FlashMessageType.error));
      dispatch(actions.removeFailed(error));
    }
  };
}
