import React from "react";
import { List as ListIcon, PowerSettingsNew, Home, TimerRounded, DashboardRounded, RoomRounded, SecurityRounded, InfoRounded } from "@material-ui/icons";
import { ListItem, ListItemText, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { palette } from "../../../css/theme";
import classNames from "classnames";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

export interface NavBarAction {
  title: string;
  link?: string;
  onClick?: () => void;
  IconComponent?: React.ComponentType;
  isSelected?: boolean;
}

export const assignSelectedItem = (items: NavBarAction[], currentLink: string) => {
  items.forEach(item => {
    item.isSelected = item.link === currentLink;
  });
};

export const actions = {
  home: { title: "Accueil", link: "/", IconComponent: Home },
  products: {
    title: "Produits",
    link: `/admin/products`,
    IconComponent: ListIcon
  },
  timeSlots: {
    title: "Créneaux",
    link: `/admin/timeslots`,
    IconComponent: TimerRounded
  },
  infos: {
    title: "Informations",
    link: "/admin/infos",
    IconComponent: InfoRounded,
  },
  locations: {
    title: "Lieux",
    link: `/admin/locations`,
    IconComponent: RoomRounded
  },
  admin: {
    title: "Admin",
    link: `/admin`,
    IconComponent: SecurityRounded
  },
  ordersAdmin: {
    title: "Commandes",
    link: `/admin/orders`,
    IconComponent: DashboardRounded
  },
  appInfos: {
    title: "Message du jour",
    link: `/app/infos`,
    IconComponent: InfoRounded
  },
  newOrder: {
    title: "Nouvelle Commande",
    link: `/app/cart`,
    IconComponent: DashboardRounded
  },
  orders: {
    title: "Mes Commandes",
    link: `/app/orders`,
    IconComponent: ListIcon
  },
  profile: {
    title: "Profile",
    link: `/app/profile`,
    IconComponent: DashboardRounded
  },
  signup: {
    title: "Connexion",
    link: "/signup"
  },
  signin: {
    title: "Connexion",
    link: "/signin"
  },
  signout: (onClick: () => void) => ({
    title: "Déconnexion",
    onClick,
    IconComponent: PowerSettingsNew
  })
};

interface ActionItemProps extends NavBarAction {
  hideIcon?: boolean;
}

/// A component to display an action inside a List
export const ActionListItem: React.FunctionComponent<ActionItemProps> = props => {
  const { title, onClick, IconComponent, link, hideIcon } = props;
  return (
    <React.Fragment>
      {!!onClick && (
        <ListItem button onClick={onClick}>
          {!hideIcon && IconComponent && <IconComponent />}
          <ListItemText primary={title} />
        </ListItem>
      )}
      {!!link && (
        <Link to={link} style={{ color: `${palette.darkTextColor}`, textDecoration: "none" }}>
          <ListItem button>
            {!hideIcon && IconComponent && <IconComponent />}
            <ListItemText primary={title} />
          </ListItem>
        </Link>
      )}
    </React.Fragment>
  );
};

const styles = () =>
  createStyles({
    navLink: {
      color: palette.black,
      textDecoration: "none",
      whiteSpace: "nowrap"
    },
    linkContainer: {
      padding: "4px"
    },
    root: {
      position: "relative",
      "&.selected": {
        "&::after": {
          content: "''",
          display: "block",
          width: "16px",
          height: 0,
          borderTop: `5px solid ${palette.primaryBlue}`,
          position: "absolute",
          left: 0,
          right: 0,
          top: "30px",
          bottom: 0,
          margin: "auto"
        }
      }
    }
  });

/// A component to display an action inside the NavBar
const ActionBarItemRaw: React.FunctionComponent<ActionItemProps & WithStyles<typeof styles>> = props => {
  const { title, onClick, IconComponent, link, hideIcon, isSelected } = props;
  const { root, navLink, linkContainer } = props.classes;

  return (
    <React.Fragment>
      {!!link && (
        <Grid className={classNames(linkContainer, root, { selected: isSelected })} item>
          <Link className={navLink} to={link} style={{ padding: "0px" }}>
            <Button size="medium" variant="text" style={{ padding: "6px 8px" }}>
              {!hideIcon && IconComponent && <IconComponent />}
              <div style={{ color: palette.darkTextColor, padding: "4px" }}>{title}</div>
            </Button>
          </Link>
        </Grid>
      )}
      {!!onClick && (
        <Grid className={classNames(linkContainer, root, { selected: isSelected })} item>
          <Button className={navLink} size="medium" variant="text" style={{ padding: "6px 8px" }}>
            {!hideIcon && IconComponent && <IconComponent />}
            <div style={{ color: palette.darkTextColor, padding: "4px" }}>{title}</div>
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};

export const ActionBarItem = withStyles(styles)(ActionBarItemRaw);
