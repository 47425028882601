import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Layout } from "../Layout";
import { AdminNavBar } from "../Layout/NavBar";
import { Switch, Route } from "react-router";
import { Products } from "./Products/Products";
import CreateProduct from "./Products/CreateProduct";
import { TimeSlots } from "./Timeslots/TimeSlots";
import CreateTimeSlot from "./Timeslots/CreateTimeSlot";
import { Orders } from "./Orders/Orders";
import { ProductAvailability } from "./Products/ProductAvailability";
import { Locations } from "./Locations/Locations";
import CreateLocation from "./Locations/CreateLocation";
import { Cart } from "../Application/Cart/Cart";
import { CartRecap } from "../Application/Cart/CartRecap";
import { Infos } from './Infos/Infos';
import UpdateInfos from "./Infos/UpdateInfos";

interface AdminProps extends WithStyles<typeof styles> {}

export const styles = (theme: Theme) => createStyles({});

const Admin: FunctionComponent<AdminProps> = props => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <Layout navBar={<AdminNavBar />}>
      <Switch>
        <Route exact path="/admin/products" component={Products} />
        <Route exact path="/admin/products/availability" component={ProductAvailability} />
        <Route path="/admin/products/:productId" component={CreateProduct} />

        <Route exact path="/admin/timeslots" component={TimeSlots} />
        <Route path="/admin/timeslots/:timeslotId" component={CreateTimeSlot} />

        <Route exact path="/admin/infos" component={Infos} />
        <Route exact path="/admin/infos/update" component={UpdateInfos} />

        <Route exact path="/admin/locations" component={Locations} />
        <Route path="/admin/locations/:locationId" component={CreateLocation} />

        <Route exact path="/admin/orders" component={Orders} />
        <Route exact path="/admin/orders/:timeslotId/new" render={props => <Cart {...props} adminMode={true} />} />
        <Route exact path="/admin/orders/:timeslotId/review" render={props => <CartRecap {...props} adminMode={true} />} />
      </Switch>
    </Layout>
  );
};

export default withStyles(styles)(Admin);
