import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import { Paper, Typography, TableBody, Table, TableRow, TableCell, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Location } from "../../../models/Location";

interface LocationListProps extends React.HTMLAttributes<HTMLDivElement> {
  locations: Map<string, Location>;
}

export const LocationList: FunctionComponent<LocationListProps> = props => {
  const { locations, className } = props;

  return (
    <Paper className={className} elevation={1}>
      <Table>
        <TableBody>
          {locations &&
            locations.entrySeq().map(([key, location]) => {
              return (
                <TableRow key={key}>
                  <TableCell style={{ padding: "24px" }} padding="none">
                    <Link color="textPrimary" component={RouterLink} to={`locations/${key}`}>
                      <Typography variant="body1">{location.name}</Typography>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};
