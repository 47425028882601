import { Action } from "../index";
import { Reducer } from "redux";
import { Map } from "immutable";
import { Product } from "../../models/Product";
import { ActionType } from "typesafe-actions";

export type ProductAction = ActionType<typeof import("./actions")>;
export class ProductState {
  readonly list: Map<string, Product> = Map();
  readonly loading: boolean = false;
  readonly loadingFailed: boolean = false;
  readonly creating: boolean = false;
  readonly deleting: boolean = false;
}

type ProductsReducer = Reducer<ProductState, Action>;

export const productsReducer: ProductsReducer = function(state = new ProductState(), action): ProductState {
  switch (action.type) {
    case "PRODUCTS/LIST":
      return {
        ...state,
        loading: true,
        loadingFailed: false
      };
    case "PRODUCTS/LIST_SUCCESS": {
      const previousList = state.list;
      const list = previousList.mergeDeep(action.payload.map((product: Product) => [product.id, product]));
      return {
        ...state,
        loading: false,
        list
      };
    }
    case "PRODUCTS/LIST_FAILED":
      return {
        ...state,
        loading: false,
        loadingFailed: true
      };
    case "PRODUCTS/CREATE":
      return {
        ...state,
        creating: true
      };
    case "PRODUCTS/CREATE_SUCCESS": {
      const list = state.list.set(action.payload.id, action.payload);
      return {
        ...state,
        creating: false,
        list
      };
    }
    case "PRODUCTS/CREATE_FAILED": {
      return {
        ...state,
        creating: false
      };
    }
    case "PRODUCTS/DELETE": {
      return {
        ...state,
        deleting: true
      };
    }
    case "PRODUCTS/DELETE_SUCCESS": {
      const list = state.list.remove(action.payload);
      return {
        ...state,
        deleting: false,
        list
      };
    }
    case "PRODUCTS/DELETE_FAILED": {
      return {
        ...state,
        deleting: false
      };
    }
    default:
      return state;
  }
};
