import { Order } from "../../models/Order";
import { createAction } from "typesafe-actions";

export const enableOrders = createAction("ORDERS/ENABLED", action => {
  return (enabled: boolean, delivery: boolean) => action({ enabled, delivery });
});

export const listDailyOrders = createAction("ORDERS/LIST_ADMIN");
export const listDailyOrdersSuccess = createAction("ORDERS/LIST_ADMIN_SUCCESS", action => {
  return (orders: Order[]) => action(orders);
});
export const listDailyOrdersFailed = createAction("ORDERS/LIST_ADMIN_FAILED", action => {
  return (error: Error) => action(error);
});

export const listUserOrders = createAction("ORDERS/LIST_USER");
export const listUserOrdersSuccess = createAction("ORDERS/LIST_USER_SUCCESS", action => {
  return (orders: Order[]) => action(orders);
});
export const listUserOrdersFailed = createAction("ORDERS/LIST_USER_FAILED", action => {
  return (error: Error) => action(error);
});

export const create = createAction("ORDERS/CREATE");
export const createSuccess = createAction("ORDERS/CREATE_SUCCESS", action => {
  return (order: Order) => action(order);
});
export const createFailed = createAction("ORDERS/CREATE_FAILED", action => {
  return (error: Error) => action(error);
});

export const remove = createAction("ORDERS/DELETE");
export const removeSuccess = createAction("ORDERS/DELETE_SUCCESS", action => {
  return (id: string) => action(id);
});
export const removeFailed = createAction("ORDERS/DELETE_FAILED", action => {
  return (error: Error) => action(error);
});
