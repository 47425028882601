import { ThunkResult } from "..";
import { API } from "../../utils/API";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import { Payload } from "../../models/Payload";
import * as actions from "./actions";
import { Product } from "../../models/Product";

export function listProducts(): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.list());
    try {
      const api = new API();
      const response = await api.listProducts();
      const action = actions.listSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.listFailed(error));
    }
  };
}

export function createProduct(product: Payload<Product>): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.createProduct(product);
      dispatch(showFlash("Produit créé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la création du produit" + error, FlashMessageType.error));
      dispatch(actions.createFailed(error));
    }
  };
}

export function updateProduct(product: Product): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.updateProduct(product);
      dispatch(showFlash("Produit sauvegardé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      showFlash("Erreur lors de la sauvegarde du produit" + error, FlashMessageType.error);
      dispatch(actions.createFailed(error));
    }
  };
}

export function deleteProduct(id: string): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.remove());
    try {
      const api = new API();
      await api.deleteProduct(id);
      dispatch(showFlash("Produit supprimé", FlashMessageType.success));
      dispatch(actions.removeSuccess(id));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la suppression du produit" + error, FlashMessageType.error));
      dispatch(actions.removeFailed(error));
    }
  };
}
