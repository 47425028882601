import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { User } from "../../../models/User";
import { Payload } from "../../../models/Payload";
import { signUp } from "../../../store/auth/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import Layout from "../../Layout/Layout";
import { Link as RouterLink } from "react-router-dom";
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'


type UserPayload = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    paper: {
      margin: "16px"
    }
  });

interface FormikSignUpProps extends FormikProps<UserPayload>, ConnectedSignUpProps, WithStyles<typeof styles> {}

class SignUp extends React.Component<FormikSignUpProps> {
  
  onPhoneNumberChange = (value: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('phoneNumber', value);
  }


  render() {
    const { values, errors, handleChange, touched, handleBlur, history, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle, paper } = this.props.classes;
    console.log(values.phoneNumber)

    return (
      <Layout navBar={<div />}>
        <Grid className={sectionTitle} container justify="space-between" alignItems="center">
          <Grid item xs>
            <Typography align="left" variant="h2">
              Créer un compte
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => history.replace("/")}>
              Accueil
            </Button>
          </Grid>
        </Grid>

        <Link component={RouterLink} to={`/signin`}>
          J'ai déjà un compte
        </Link>

        <Paper className={paper}>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="email"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="jeankestonblé@gmail.com"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="given-name"
                  name="firstName"
                  label="Prénom"
                  placeholder="Jean"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="family-name"
                  name="lastName"
                  label="Nom"
                  placeholder="Kestonblé"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  value={values.phoneNumber}
                  error={touched.phoneNumber ? errors.phoneNumber : undefined}
                  onChange={this.onPhoneNumberChange}
                  country="FR"
                  placeholder="Numéro de téléphone"
                  international={false}
                 >
                </PhoneInput>
               
              </Grid>

              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  label="Mot de passe"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>

              <Grid item xs={12}>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                  Valider
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Layout>
    );
  }
}

const StyledSignUp = withStyles(styles)(SignUp);

const FormikSignUp = withFormik<ConnectedSignUpProps, UserPayload>({
  mapPropsToValues: props => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, signUp } = formikBag.props;
    const success = await signUp(values, values.password);
    if (success) {
      history.push(`/verifyEmail?email=${values.email}`);
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().required("Prénom requis"),
    lastName: yup.string().required("Nom requis"),
    email: yup
      .string()
      .email("Email invalide")
      .required("Email requis"),
    phoneNumber: yup
      .string()
      .required("Téléphone requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledSignUp);

interface ConnectedSignUpProps extends RouteComponentProps {
  signUp: (user: Payload<User>, password: string) => Promise<boolean>;
}

export default connect(
  null,
  { signUp }
)(FormikSignUp);
