import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { signIn } from "../../../store/auth/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import Layout from "../../Layout/Layout";
import { Link as RouterLink } from "react-router-dom";

type SignInPayload = {
  email: string;
  password: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    paper: {
      margin: "16px"
    }
  });

interface FormikSignInProps extends FormikProps<SignInPayload>, ConnectedSignInProps, WithStyles<typeof styles> {}

class SignIn extends React.Component<FormikSignInProps> {
  render() {
    const { values, errors, handleChange, touched, handleBlur, history, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle, paper } = this.props.classes;

    return (
      <Layout navBar={<div />}>
        <Grid className={sectionTitle} container justify="space-between" alignItems="center">
          <Grid item xs>
            <Typography align="left" variant="h2">
              Connexion
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => history.replace("/")}>
              Accueil
            </Button>
          </Grid>
        </Grid>

        <Link component={RouterLink} to={`/signup`}>
          Je n'ai pas encore de compte.
        </Link>

        <Paper className={paper}>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <FormikTextField autoComplete="email" type="email" name="email" label="Email" fullWidth {...fieldProps} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  autoComplete="password"
                  type="password"
                  name="password"
                  label="Mot de passe"
                  fullWidth
                  {...fieldProps}
                />
              </Grid>

              <Grid item xs={12}>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                  Valider
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link component={RouterLink} to={`/forgotPassword`}>
                  <Typography color="textSecondary"> Mot de passe oublié? </Typography>
                </Link>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Layout>
    );
  }
}

const StyledSignIn = withStyles(styles)(SignIn);

const FormikSignIn = withFormik<ConnectedSignInProps, SignInPayload>({
  mapPropsToValues: props => {
    return {
      email: "",
      password: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, signIn } = formikBag.props;
    const success = await signIn(values.email, values.password);
    if (success) {
      history.replace("/app/cart");
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Email invalide")
      .required("Champ requis"),
    password: yup.string().min(10, "Votre mot de passe doit comporter 10 caractères minimum")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledSignIn);

interface ConnectedSignInProps extends RouteComponentProps {
  signIn: (email: string, password: string) => Promise<boolean>;
}

export default connect(
  null,
  { signIn }
)(FormikSignIn);
