import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { Grid, Typography, Button, Paper, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { InfosState } from "../../../store/infos/reducer";
import { getInfos } from "../../../store/infos/thunks";

interface InfosProps extends WithStyles<typeof styles> {
  infos: InfosState;
  getInfos: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: "32px",
    },
    messageContainer: {
      backgroundColor: "#FAFAFA",
      minHeight: "250px",
      marginTop: "24px",
      padding: "24px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
  });

const InfosRaw: FunctionComponent<InfosProps> = (props) => {
  const { getInfos } = props;
  const { loading, content } = props.infos;
  const { messageContainer, sectionTitle } = props.classes;

  useEffect(() => {
    getInfos();
  }, [getInfos]);

  return (
    <React.Fragment>
      <Grid className={sectionTitle} container direction="row" justify="space-between">
        <Grid item>
          <Typography variant="h2"> Informations </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary">
            <Link color="inherit" component={RouterLink} to="infos/update">
              Mettre à jour
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={0}>
       
          <Typography align="left" variant="h5"> Message actuel </Typography>
        <div className={messageContainer}>
          {loading && "Chargement du message d'accueil"}
          {!loading && !content && "Pas de message d'accueil configuré"}
          {!loading && !!content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state: State) {
  return {
    infos: state.infos,
  };
}

export const Infos = withStyles(styles)(connect(mapStateToProps, { getInfos })(InfosRaw));
