import React, { FunctionComponent } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { ProductState } from "../../../store/products/reducer";
import { Grid, Typography, Button, Paper, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ProductList } from "./ProductList";

interface ProductListProps extends WithStyles<typeof styles> {
  products: ProductState;
}

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: "32px"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const ProductsRaw: FunctionComponent<ProductListProps> = props => {
  const { loading, list } = props.products;
  const { listContainer, sectionTitle } = props.classes;
  return (
    <React.Fragment>
      <Grid className={sectionTitle} container direction="row" justify="space-between">
        <Grid item>
          <Typography variant="h2"> Produits </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary">
            <Link color="inherit" component={RouterLink} to="products/new">
              Nouveau Produit
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <div className={listContainer}>
          {loading && "Chargement des produits"}
          {!loading && !!list && list.size === 0 && "Aucun produit créé"}
          {!loading && !!list && list.size > 0 && <ProductList products={list} />}
        </div>
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state: State) {
  return {
    products: state.products
  };
}

export const Products = withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(ProductsRaw)
);
