import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { Typography } from "@material-ui/core";
import { InfosState } from "../../../store/infos/reducer";
import { getInfos } from "../../../store/infos/thunks";

interface InfosProps extends WithStyles<typeof styles> {
  infos: InfosState;
  getInfos: () => void;
  enabled: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    messageContainer: {
      backgroundColor: "#FAFAFA",
      minHeight: "250px",
      marginTop: "24px",
      padding: "24px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    },
    textContainer: {
      padding: theme.spacing(4),
    },
  });

const InfosRaw: FunctionComponent<InfosProps> = (props) => {
  const { getInfos, enabled } = props;
  const { loading, content } = props.infos;
  const { messageContainer, textContainer } = props.classes;

  useEffect(() => {
    getInfos();
  }, [getInfos]);

  return (
    <div >
      {!enabled && (
        <Typography variant="h3" className={textContainer}>
          Les commandes ne sont pas encore ouvertes pour ce soir!
        </Typography>
      )}
      <div className={messageContainer}>
        {loading && !content && "Chargement du message d'accueil"}
        {!loading && !content && "Pas de message d'accueil configuré"}
        {!!content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
    </div>
  );
};

function mapStateToProps(state: State) {
  return {
    infos: state.infos,
    enabled: state.orders.enabled,
  };
}

export const Infos = withStyles(styles)(connect(mapStateToProps, { getInfos })(InfosRaw));
