import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Layout } from "../Layout";
import { HomeNavBar } from "../Layout/NavBar";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../store";
import { getInfos } from "../../store/infos/thunks";
import { InfosState } from "../../store/infos/reducer";

interface HomeProps extends WithStyles<typeof styles> {
  infos: InfosState;
  getInfos: () => void;
}

export const styles = (theme: Theme) =>
  createStyles({
    banner: {
      marginTop: "64px",
      width: "100%",
    },
    layout: {
      paddingTop: "0",
    },
    tagLine: {
      padding: "24px",
    },
    buttonContainer: {
      padding: "10px 20px",
    },
    messageContainer: {
      backgroundColor: "#FAFAFA",
      minHeight: "250px",
      marginTop: "24px",
      padding: "24px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    linkClass: {
      color: "inherit",
      padding: "10px 20px",
      textDecoration: "none",
      whiteSpace: "nowrap",
    },
  });

const HomeRaw: FunctionComponent<HomeProps> = (props) => {
  const { getInfos, infos } = props;
  useEffect(() => window.scrollTo(0, 0));
  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const { banner, layout, tagLine, messageContainer } = props.classes;
  return (
    <Layout className={layout} navBar={<HomeNavBar />} footer>
      <img className={banner} src="/banner.jpg" alt="le camion magique"></img>

      <Typography className={tagLine} variant="h2" color="textPrimary">
        Bienvenue sur le site du camion magique !
      </Typography>
      {infos.loading && !infos.content && "Chargement des actualités..."}
      {!infos.loading && !infos.content && "Ouverture des commandes au shotgun aux alentours de 17h"}
      {!!infos.content && <div className={messageContainer} dangerouslySetInnerHTML={{ __html: infos.content }} />}
    </Layout>
  );
};

function mapStateToProps(state: State) {
  return {
    infos: state.infos,
  };
}

export default withStyles(styles)(connect(mapStateToProps, { getInfos })(HomeRaw));
