import { ThunkResult } from "..";
import { API } from "../../utils/API";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import { Payload } from "../../models/Payload";
import * as actions from "./actions";
import { Location } from "../../models/Location";

export function listLocations(): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.list());
    try {
      const api = new API();
      const response = await api.listLocations();
      const action = actions.listSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.listFailed(error));
    }
  };
}

export function createLocation(location: Payload<Location>): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.createLocation(location);
      dispatch(showFlash("Lieu créé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la création du produit" + error, FlashMessageType.error));
      dispatch(actions.createFailed(error));
    }
  };
}

export function updateLocation(location: Location): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.create());
    try {
      const api = new API();
      const response = await api.updateLocation(location);
      dispatch(showFlash("Lieu sauvegardé", FlashMessageType.success));
      dispatch(actions.createSuccess(response));
    } catch (error) {
      console.error(error);
      showFlash("Erreur lors de la sauvegarde du produit" + error, FlashMessageType.error);
      dispatch(actions.createFailed(error));
    }
  };
}

export function deleteLocation(id: string): ThunkResult<void> {
  return async function(dispatch) {
    dispatch(actions.remove());
    try {
      const api = new API();
      await api.deleteLocation(id);
      dispatch(showFlash("Lieu supprimé", FlashMessageType.success));
      dispatch(actions.removeSuccess(id));
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la suppression du produit" + error, FlashMessageType.error));
      dispatch(actions.removeFailed(error));
    }
  };
}
