import React from "react";
import { Button, createStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavBarAction } from "./NavBarActions";
import { withStyles, WithStyles } from "@material-ui/styles";

const styles = () =>
  createStyles({
    buttonContainer: {
      float: "right",
      padding: "10px 20px"
    },
    linkClass: {
      color: "inherit",
      padding: "10px 20px",
      textDecoration: "none",
      whiteSpace: "nowrap"
    }
  });

class RightButtonRaw extends React.Component<{ action: NavBarAction } & WithStyles<typeof styles>> {
  render() {
    const { title, link, onClick } = this.props.action;
    const { buttonContainer, linkClass } = this.props.classes;

    return (
      <div className={buttonContainer}>
        <Button variant="contained" color="secondary" style={{ padding: "0px" }} onClick={onClick}>
          {link && (
            <Link to={link!} className={linkClass}>
              {title}
            </Link>
          )}
          {!link && (
            <Typography className={linkClass} variant="button">
              {title}
            </Typography>
          )}
        </Button>
      </div>
    );
  }
}

export const RightButton = withStyles(styles)(RightButtonRaw);
