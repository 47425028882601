import React, { FunctionComponent, useState } from "react";
import { Paper, Typography, Grid, TableBody, Table, TableRow, TableCell, Switch } from "@material-ui/core";
import { Product } from "../../../models/Product";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { ProductState } from "../../../store/products/reducer";
import { updateProduct } from "../../../store/products/thunks";

interface ProductListProps extends WithStyles<typeof styles> {
  products: ProductState;
  updateProduct: (product: Product) => void;
}

const styles = () =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const ProductAvailabilityRaw: FunctionComponent<ProductListProps> = props => {
  const { loading, list } = props.products;
  const { listContainer, sectionTitle } = props.classes;

  const [editing, setEditing] = useState(list.map(() => false));

  const onSwitchClick = async (product: Product) => {
    const { updateProduct } = props;
    setEditing(editing.set(product.id, true));
    product.available = !product.available;
    await updateProduct(product);
    setEditing(editing.set(product.id, false));
  };

  return (
    <React.Fragment>
      <Grid className={sectionTitle} container direction="row" justify="space-between">
        <Grid item>
          <Typography variant="h2"> Produits Disponibles </Typography>
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <div className={listContainer}>
          {loading && "Chargement des produits"}
          {!loading && !!list && list.size === 0 && "Aucun produit créé"}
          {!loading && !!list && list.size > 0 && (
            <Paper elevation={1}>
              <Table>
                <TableBody>
                  {list &&
                    list.entrySeq().map(([key, product]) => {
                      return (
                        <TableRow key={key}>
                          <TableCell style={{ padding: "24px" }} padding="none">
                            <Grid container alignItems="center" justify="space-between">
                              <Grid item>
                                <Typography variant="body1">{product.name}</Typography>
                              </Grid>
                              <Grid item>
                                <Switch
                                  color="primary"
                                  checked={product.available}
                                  disabled={editing.get(product.id)}
                                  onChange={() => onSwitchClick(product)}
                                ></Switch>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state: State) {
  return {
    products: state.products
  };
}

export const ProductAvailability = withStyles(styles)(
  connect(
    mapStateToProps,
    { updateProduct }
  )(ProductAvailabilityRaw)
);
