import React from "react";
import { Button, List, Drawer } from "@material-ui/core";
import { NavBarAction, ActionListItem } from "./NavBarActions";
import MenuIcon from "@material-ui/icons/Menu";

interface DrawerProps {
  actions: NavBarAction[];
  hideIcons?: boolean;
}
export class LeftDrawer extends React.Component<DrawerProps> {
  state = {
    open: false
  };

  toggleDrawer = (open: boolean) => () => {
    this.setState({
      open: open
    });
  };

  render() {
    const { actions, hideIcons } = this.props;
    return (
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button onClick={this.toggleDrawer(true)}>
          <MenuIcon />
        </Button>
        <Drawer anchor="left" open={this.state.open} onClose={this.toggleDrawer(false)}>
          <div tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
            <List>
              {actions.map(action => (
                <ActionListItem key={action.title} {...action} hideIcon={hideIcons} />
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}
