import { ThunkResult } from "..";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import * as actions from "./actions";
import { ProductOrder } from "../../models/Order";
import { Map } from "immutable";
import { v4 as uuid } from "uuid";
import { getAvailableTimeslots } from "../../utils/timeslots";

export function addItems(products: ProductOrder[]): ThunkResult<void> {
  return async function(dispatch, getState) {
    const existingProducts = getState().cart.products;
    const newProducts = Map(products.map(product => [uuid(), product]));
    const allProducts = existingProducts.merge(newProducts);
    const timeslots = getState().timeslots.list;
    const hasAvailability = !getAvailableTimeslots(allProducts, timeslots).isEmpty();

    if (hasAvailability) {
      dispatch(actions.addItems(products));
    } else {
      dispatch(
        showFlash(
          "Il n'y a plus de créneaux disponibles pour une commande de cette taille. Merci de faire des commandes séparées.",
          FlashMessageType.warning
        )
      );
    }
  };
}
