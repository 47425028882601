import React, { FunctionComponent } from "react";
import { Map } from "immutable";
import { GridList, GridListTile, List } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import { Order } from "../../../models/Order";
import { TimeSlot } from "../../../models/TimeSlot";
import { Product } from "../../../models/Product";
import { WithStyles } from "@material-ui/styles";
import { OrderItem } from "./OrderItem";
import { AddCircle } from "@material-ui/icons";
import { OrderListHeader } from "./OrderListHeader";
import LinkButton from "../../../components/LinkButton";

interface OrderListProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  orders: Map<string, Order>;
  timeslots: Map<string, TimeSlot>;
  products: Map<string, Product>;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "left-align",
      overflow: "hidden",
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    timeslotRoot: {
      maxHeight: "100%"
    },
    gridList: {
      width: "100%",
      height: "100%",
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)"
    },
    orderButton: {
      width: "100%"
    },
    gridListInnerContainer: {
      padding: "4px"
    }
  });

const OrderListRaw: FunctionComponent<OrderListProps> = props => {
  const { orders, timeslots, products } = props;
  const { gridList, root, timeslotRoot, gridListInnerContainer, orderButton } = props.classes;

  return (
    <div className={root}>
      <GridList className={gridList} cols={3} cellHeight="auto" spacing={6}>
        {timeslots &&
          timeslots.entrySeq().map(([key, timeslot]) => {
            const timeslotOrders = orders
              .filter(order => order.timeslot === key)
              .sort((orderA, orderB) => {
                if ((!!orderA.delivery && !!orderB.delivery) || (!orderA.delivery && !orderB.delivery)) {
                  return orderA.createdAt - orderB.createdAt;
                } else if (orderA.delivery && !orderB.delivery) {
                  return -1;
                } else {
                  return 1;
                }
              });
            return (
              <GridListTile key={key}>
                <div className={gridListInnerContainer}>
                  <OrderListHeader timeslot={timeslot}></OrderListHeader>
                  <LinkButton
                    to={`orders/${timeslot.id}/new`}
                    disabled={!timeslot.available}
                    className={orderButton}
                    variant="contained"
                    color="secondary"
                  >
                    <AddCircle />
                  </LinkButton>

                  <div className={timeslotRoot}>
                    <List>
                      {timeslotOrders.entrySeq().map(([orderKey, order]) => (
                        <OrderItem key={orderKey} order={order} products={products} enabled={timeslot.available}></OrderItem>
                      ))}
                    </List>
                  </div>
                </div>
              </GridListTile>
            );
          })}
      </GridList>
    </div>
  );
};

export const OrderList = withStyles(styles)(OrderListRaw);
