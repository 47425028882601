import { ProductOrder } from "../../models/Order";
import { createAction } from "typesafe-actions";

export const addItems = createAction("CART/ADD", action => {
  return (products: ProductOrder[]) => action(products);
});

export const removeItem = createAction("CART/REMOVE", action => {
  return (productOrderId: string) => action(productOrderId);
});

export const setDelivery = createAction("CART/SET_DELIVERY", action => {
  return (delivery?: string) => action(delivery)
})

export const clear = createAction("CART/CLEAR");
