import React from "react";
import { Typography } from "@material-ui/core";

export const NotFound = () => {
  return (
    <Typography variant="h1" align="center">
      We're sorry but this page does not exist.
    </Typography>
  );
};
