import React, { FunctionComponent, ReactEventHandler, useState } from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Fab,
  FormControlLabel,
  Checkbox,
  Select,
  IconButton
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { ModalData, CartItemModal } from "../../../store/application/modal";
import { CloseRounded, RemoveCircleRounded, AddCircleRounded } from "@material-ui/icons";
import { palette } from "../../../css/theme";
import { connect } from "react-redux";
import {  ProductOrder } from "../../../models/Order";

const styles = createStyles({
  root: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  title: {
    flexGrow: 1
  },
  subtitle: {
    paddingTop: "16px"
  },
  button: {
    width: "100%",
    height: "80px"
  },
  minusButton: {
    color: palette.gray4
  },
  plusButton: {
    "& svg": {
      fontSize: 40
    }
  }
});

interface Props extends WithStyles<typeof styles>, Omit<DialogProps, "classes">, ModalData<CartItemModal> {}

const CartItemModalDialog: FunctionComponent<Props> = props => {
  const { classes, product, addItems, ...rest } = props;
  const { root, title, subtitle, button, minusButton, plusButton } = classes;

  const { options, flavors } = product;
  const firstFlavor = flavors ? flavors[0] : ""

  const [count, setCount] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [flavor, setFlavor] = useState<string>(firstFlavor);

  const addItem = () => {
    setCount(count + 1);
  };

  const removeItem = () => {
    setCount(count - 1);
  };

  const validateItem: ReactEventHandler = event => {
    const { onClose } = props;

    const productOrder: ProductOrder = {
      productId: product.id,
      productType: product.type,
      options: !!options ? selectedOptions.join(",") : undefined,
      flavor: !!flavor ? flavor : undefined
    };
    const items = Array(count).fill(productOrder);
    addItems(items);
    onClose && onClose(event, "escapeKeyDown");
  };

  const handleOptionChange = (option: string, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter(value => value !== option));
    }
  };

  const handleFlavorChange = (flavor: string) => {
    setFlavor(flavor);
  };

  const onClick: ReactEventHandler = event => {
    const { onClose } = props;
    onClose && onClose(event, "escapeKeyDown");
  };

  return (
    <Dialog classes={{ paper: root }} fullWidth open {...rest}>
      <DialogTitle>
        <Grid container alignItems="center" justify="flex-end">
          <Grid className={title} item>
            <Typography variant="h6">{product.name}</Typography>
          </Grid>
          <Grid item>
            <Fab size="small" onClick={onClick}>
              <CloseRounded />
            </Fab>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justify="center" spacing={1}>
          {options && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography className={subtitle} color="textSecondary" variant="h6">
                  Options
                </Typography>
              </Grid>
              {options.map(option => {
                return (
                  <Grid key={option} item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.includes(option)}
                          onChange={(event, checked) => handleOptionChange(option, checked)}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  </Grid>
                );
              })}
            </React.Fragment>
          )}
          {flavors && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography className={subtitle} color="textSecondary" variant="h6">
                  Sauce
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  native
                  value={flavor}
                  inputProps={{ name: "flavor" }}
                  onChange={event => handleFlavorChange(event.target.value as string)}
                >
                  {flavors.map(flavor => (
                    <option key={flavor} value={flavor}>
                      {flavor}
                    </option>
                  ))}
                </Select>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <Grid className={subtitle} container justify="flex-start" alignItems="center" wrap="nowrap">
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="h6">
                  Quantité: {count}
                </Typography>
              </Grid>
              {count > 1 && (
                <Grid item>
                  <IconButton className={minusButton} size="small" color="inherit" onClick={removeItem}>
                    <RemoveCircleRounded />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <IconButton className={plusButton} size="small" color="primary" onClick={addItem}>
                  <AddCircleRounded />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "36px" }}>
            <Button className={button} variant="contained" color="secondary" onClick={validateItem}>
              Ajouter {count} {product.name}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  null,
  {}
)(withStyles(styles)(CartItemModalDialog));
