import { Reducer } from "redux";
import { ActionType } from "typesafe-actions";
import { Action } from "../index";

export type InfosActions = ActionType<typeof import("./actions")>;

export class InfosState {
  readonly content?: string = undefined;
  readonly loading: boolean = false;
  readonly loadingFailed: boolean = false;
}

type InfosReducer = Reducer<InfosState, Action>;

export const infosReducer: InfosReducer = function (state = new InfosState(), action): InfosState {
  switch (action.type) {
    case "INFOS/GET":
      return { ...state, loading: true, loadingFailed: false };
    case "INFOS/GET_SUCCESS":
      return { ...state, content: action.payload.content, loading: false, loadingFailed: false };
    case "INFOS/GET_FAILED":
      return { ...state, loading: false, loadingFailed: true };
    default:
      return state;
  }
};
