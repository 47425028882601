import { ThunkResult } from "..";
import { API } from "../../utils/API";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";
import { Payload } from "../../models/Payload";
import * as actions from "./actions";
import { User } from "../../models/User";
import { Auth } from "../../utils/Auth";
import { storageHelper } from "../../utils/storageHelper";

export function signUp(payload: Payload<User>, password: string): ThunkResult<Promise<boolean>> {
  return async function(dispatch) {
    dispatch(actions.signUp());
    try {
      await Auth.signUp(payload, password);
      dispatch(actions.signUpSuccess(payload, password));
      return true;
    } catch (error) {
      console.error(error);
      const message = error.message ? error.message : `${error}`;
      dispatch(showFlash(message, FlashMessageType.error));
      dispatch(actions.signUpFailed(error));
      return false;
    }
  };
}

export function confirmEmail(email: string, code: string): ThunkResult<Promise<string | undefined>> {
  return async function(dispatch, getState) {
    dispatch(actions.signUp());
    try {
      await Auth.confirmSignUp(email, code);
      const { password } = getState().auth;
      if (!password) {
        dispatch(showFlash("Merci de vous reconnecter", FlashMessageType.warning));
        return "/signin";
      }

      const { attributes } = await Auth.signIn(email, password);
      const user = await createUser(attributes);
      dispatch(actions.confirmEmailSuccess(user));
      return "/app/cart";
    } catch (error) {
      console.error(error);
      const message = error.message ? error.message : `${error}`;
      dispatch(showFlash(message, FlashMessageType.error));
      dispatch(actions.signUpFailed(error));
      return undefined;
    }
  };
}

export function signIn(email: string, password: string): ThunkResult<Promise<boolean>> {
  return async function(dispatch) {
    dispatch(actions.signUp());
    let attributes: Record<string, string> | undefined = undefined;

    try {
      attributes = (await Auth.signIn(email, password)).attributes;
      await dispatch(getUser());
      return true;
    } catch (error) {
      if (error.name === "ItemNotFoundException" && attributes) {
        const user = await createUser(attributes);
        dispatch(actions.signInSuccess(user));
        return true;
      } else {
        console.error(error);
        const message = error.message ? error.message : `${error}`;
        dispatch(showFlash(message, FlashMessageType.error));
        dispatch(actions.signInFailed(error));
        return false;
      }
    }
  };
}

export function getUser(): ThunkResult<void> {
  return async function(dispatch) {
    try {
      const api = new API();
      const credentials = await Auth.currentCredentials();

      // Credentials are not correctly cleared after signOut, so we need to check the authenticated flag.
      if (!credentials.authenticated || !credentials.identityId) {
        const message = `Credentials revoked: ${!credentials.authenticated}, or missing: ${!credentials.identityId}`;
        console.warn(message);
        return;
      } else {
        const user = await api.getUser();
        dispatch(actions.signInSuccess(user));
      }
    } catch (error) {
      console.error("Error getting user: ", error);
      dispatch(signOut());
      dispatch(actions.signInFailed(error));
      throw error;
    }
  };
}

async function createUser(attributes: Record<string, string>) {
  const credentials = await Auth.currentCredentials();

  let user: User = {
    id: credentials.identityId,
    firstName: attributes.given_name,
    lastName: attributes.family_name,
    email: attributes.email,
    phoneNumber: attributes.phone_number
  };

  const api = new API();
  return api.createUser(user);
}

export function resendEmail(email: string): ThunkResult<void> {
  return async function(dispatch) {
    try {
      await Auth.resendConfirmationEmail(email);
      dispatch(showFlash("Code renvoyé. Vérifiez vos spams !", FlashMessageType.success));
    } catch (error) {
      console.error(error);
      const message = error.message ? error.message : `${error}`;
      dispatch(showFlash(message, FlashMessageType.error));
    }
  };
}

export function forgotPassword(email: string): ThunkResult<Promise<boolean>> {
  return async function(dispatch) {
    try {
      await Auth.forgotPassword(email);
      return true;
    } catch (error) {
      console.error(error);
      const message = error.message ? error.message : `${error}`;
      dispatch(showFlash(message, FlashMessageType.error));
      return false;
    }
  };
}

export function resetPassword(email: string, code: string, password: string): ThunkResult<Promise<boolean>> {
  return async function(dispatch) {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      dispatch(showFlash("Mot de passe réinitialisé", FlashMessageType.success));
      return true;
    } catch (error) {
      console.error(error);
      const message = error.message ? error.message : `${error}`;
      dispatch(showFlash(message, FlashMessageType.error));
      return false;
    }
  };
}

export function signOut(): ThunkResult<void> {
  return async function(dispatch) {
    await Auth.signOut();
    storageHelper.clear();
    dispatch(actions.signOut());
  };
}
