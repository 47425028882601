import { Auth as AWSAuth } from "aws-amplify";
import { Payload } from "../models/Payload";
import { User } from "../models/User";
import { CognitoUser } from "@aws-amplify/auth";

export class Auth {
  static currentUserInfo = () => {
    return AWSAuth.currentUserInfo();
  };

  static currentAuthenticatedUser = (): Promise<CognitoUser> => {
    return AWSAuth.currentAuthenticatedUser();
  };

  static currentCredentials = () => {
    return AWSAuth.currentCredentials();
  };

  static currentSession = () => {
    return AWSAuth.currentSession();
  };

  static signOut = () => {
    return AWSAuth.signOut();
  };

  static signIn = (email: string, password: string) => {
    return AWSAuth.signIn(email.toLowerCase(), password);
  };

  static signUp = (user: Payload<User>, password: string) => {
    if (user.email) {
      user.email = user.email.toLowerCase();
    }
    const attributes = {
      email: user.email,
      given_name: user.firstName,
      family_name: user.lastName,
      phone_number: user.phoneNumber
    };
    return AWSAuth.signUp({ username: user.email, password, attributes });
  };

  static confirmSignUp = (email: string, code: string) => {
    return AWSAuth.confirmSignUp(email, code);
  };

  static forgotPassword = (email: string) => {
    return AWSAuth.forgotPassword(email.toLowerCase());
  };

  static forgotPasswordSubmit = (email: string, code: string, password: string) => {
    return AWSAuth.forgotPasswordSubmit(email, code, password);
  };

  static resendConfirmationEmail = (email: string) => {
    return AWSAuth.resendSignUp(email);
  };

  static updateUserAttributes = (user: User, attributes: any) => {
    const updatedAttributes = attributes;
    if (attributes.email) {
      updatedAttributes.email = attributes.email.toLowerCase();
    }
    return AWSAuth.updateUserAttributes(user, updatedAttributes);
  };
}
