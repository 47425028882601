import { v4 as uuid } from "uuid";

export enum FlashMessageType {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error"
}

export class FlashMessage {
  id: string;
  message: string;
  type: FlashMessageType;

  constructor(message: string, type: FlashMessageType) {
    this.id = uuid();
    this.message = message;
    this.type = type;
  }
}
