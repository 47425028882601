import { API as AWSAPI } from "aws-amplify";
import { Payload } from "../models/Payload";
import { Product } from "../models/Product";
import { TimeSlot } from "../models/TimeSlot";
import { Order } from "../models/Order";
import { Location } from "../models/Location";
import { User } from "../models/User";
import { Infos } from "../models/Infos";

const commonService = "common";
const adminService = "admin";
const customerService = "customer";

export class API {
  // Products

  listProducts = async (): Promise<Product[]> => {
    return AWSAPI.get(commonService, "/products", {});
  };

  createProduct = (product: Payload<Product>): Promise<Product> => {
    return AWSAPI.post(adminService, "/products", { body: product }).catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    });
  };

  updateProduct = (product: Product): Promise<Product> => {
    const id = product.id;
    delete product.id;
    return AWSAPI.patch(adminService, `/products/${id}`, { body: product });
  };

  deleteProduct = (id: string): Promise<void> => {
    return AWSAPI.del(adminService, `/products/${id}`, {});
  };

  // TimeSlots

  listTimeSlots = (): Promise<TimeSlot[]> => {
    return AWSAPI.get(commonService, "/timeslots", {});
  };

  createTimeSlot = (timeslot: Payload<TimeSlot>): Promise<TimeSlot> => {
    return AWSAPI.post(adminService, "/timeslots", { body: timeslot });
  };

  updateTimeSlot = (timeslot: TimeSlot): Promise<TimeSlot> => {
    const id = timeslot.id;
    delete timeslot.id;
    return AWSAPI.patch(adminService, `/timeslots/${id}`, { body: timeslot });
  };

  deleteTimeSlot = (id: string): Promise<void> => {
    return AWSAPI.del(adminService, `/timeslots/${id}`, {});
  };

  // Locations

  listLocations = async (): Promise<Location[]> => {
    return AWSAPI.get(commonService, "/locations", {});
  };

  createLocation = (location: Payload<Location>): Promise<Location> => {
    return AWSAPI.post(adminService, "/locations", { body: location });
  };

  updateLocation = (location: Location): Promise<Location> => {
    return AWSAPI.patch(adminService, `/locations/${location.id}`, { body: location });
  };

  deleteLocation = (id: string): Promise<void> => {
    return AWSAPI.del(adminService, `/locations/${id}`, {});
  };

  // Users

  getUser = async (): Promise<User> => {
    return AWSAPI.get(customerService, "/users", {}).catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    });
  };

  createUser = (user: Payload<User>): Promise<User> => {
    return AWSAPI.post(customerService, "/users", { body: user });
  };

  updateUser = (user: User): Promise<User> => {
    return AWSAPI.patch(customerService, `/users/${user.id}`, { body: user });
  };

  deleteUser = (id: string): Promise<void> => {
    return AWSAPI.del(customerService, `/users/${id}`, {});
  };

  // Orders

  listOrders = (day: string): Promise<Order[]> => {
    return AWSAPI.get(adminService, `/day/${day}/orders`, {});
  };

  listUserOrders = (): Promise<Order[]> => {
    return AWSAPI.get(customerService, "/day/orders", {});
  };

  createOrder = (order: Payload<Order>): Promise<Order> => {
    return AWSAPI.post(commonService, `/orders`, { body: order }).catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    });
  };

  editOrder = (order: Order): Promise<Order> => {
    return AWSAPI.patch(adminService, `/day/${order.day}/timeslot/${order.timeslot}/orders/${order.id}`, {
      body: {
        userId: order.userId,
        fulfilled: order.fulfilled,
      },
    });
  };

  deleteOrder = (order: Order): Promise<void> => {
    return AWSAPI.del(adminService, `/day/${order.day}/timeslot/${order.timeslot}/orders/${order.id}`, {
      body: { userId: order.userId },
    });
  };

  // Start and Stop day

  ordersEnabled = async (): Promise<{ enabled: boolean; delivery: boolean }> => {
    return AWSAPI.get(commonService, "/day/current/status", {});
  };

  setDeliveryEnabled = async (enabled: boolean): Promise<{ enabled: boolean; delivery: boolean }> => {
    return AWSAPI.patch(adminService, "/delivery/enable", { body: { enabled } });
  };

  startDay = async (): Promise<void> => {
    await AWSAPI.post(adminService, "/day/current/start", {});
    return;
  };

  stopDay = async (): Promise<void> => {
    await AWSAPI.post(adminService, "/day/current/stop", {});
    return;
  };

  // Infos

  getInfos = async (): Promise<Infos> => {
    return AWSAPI.get(commonService, "/infos", {});
  };

  createInfos = async (infos: Infos): Promise<void> => {
    return AWSAPI.put(adminService, "/infos", { body: infos });
  };
}
