import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";

import App from "./App";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./css/theme";
import * as serviceWorker from "./serviceWorker";
import amplifyConfiguration from "./config/amplify.config";
import { store } from "./store";

Amplify.configure(amplifyConfiguration);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
