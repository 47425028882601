import { ThunkResult } from "..";
import { API } from "../../utils/API";
import * as actions from "./actions";
import { showFlash } from "../application/actions";
import { FlashMessageType } from "../application/flashMessage";

export function getInfos(): ThunkResult<void> {
  return async function (dispatch) {
    dispatch(actions.infos());
    try {
      const api = new API();
      const response = await api.getInfos();
      const action = actions.infosSuccess(response);
      dispatch(action);
    } catch (error) {
      console.error(error);
      dispatch(actions.infosFailed(error));
    }
  };
}

export function createInfos(content: string): ThunkResult<void> {
  return async function (dispatch) {
    dispatch(actions.createInfos());
    try {
      const api = new API();
      await api.createInfos({ content });
      dispatch(showFlash("Message d'accueil mis à jour", FlashMessageType.success));
      dispatch(actions.createInfosSuccess());
    } catch (error) {
      console.error(error);
      dispatch(showFlash("Erreur lors de la création du produit" + error, FlashMessageType.error));
      dispatch(actions.createInfosFailed(error));
    }
  };
}
