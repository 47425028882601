import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FlashMessage } from "../store/application/flashMessage";
import { ExitHandler } from "react-transition-group/Transition";
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
import { SnackbarContent } from "@material-ui/core";
import { palette } from "../css/theme";

interface FlashMessageProps extends WithStyles<typeof styles> {
  flash: FlashMessage;
  open: boolean;
  onClose: (event: React.SyntheticEvent<any, Event>, reason?: string) => void;
  onExited: ExitHandler;
}

const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: palette.success
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.dark
    },
    warning: {
      backgroundColor: palette.warning
    }
  });

class FlashMessageStyled extends React.Component<FlashMessageProps> {
  render() {
    const { classes, flash, open, onClose, onExited } = this.props;
    return (
      <Snackbar
        key={flash.id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        onExited={onExited}
      >
        <SnackbarContent
          className={classes[flash.type]}
          aria-describedby="message-id"
          message={<span id="message-id">{flash.message}</span>}
          action={
            <IconButton aria-label="Close" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export const FlashMessageComponent = withStyles(styles)(FlashMessageStyled);
