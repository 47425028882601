import React from "react";
import { Grid } from "@material-ui/core";
import { NavBarAction, ActionBarItem, assignSelectedItem } from "./NavBarActions";
import { withRouter, RouteComponentProps } from "react-router";

interface MiddleButtonsProps extends RouteComponentProps {
  actions: NavBarAction[];
  hideIcons?: boolean;
}

class MiddleButtonsRaw extends React.Component<MiddleButtonsProps> {
  shouldComponentUpdate(nextProps: MiddleButtonsProps) {
    return nextProps.actions !== this.props.actions || nextProps.location !== this.props.location;
  }

  render() {
    const { actions, hideIcons } = this.props;
    assignSelectedItem(actions, window.location.pathname);

    return (
      <Grid container direction="row" alignItems="center" justify="center" wrap="nowrap" spacing={2}>
        {actions.map(action => (
          <ActionBarItem key={action.title} hideIcon={hideIcons} {...action} />
        ))}
      </Grid>
    );
  }
}

export const MiddleButtons = withRouter(MiddleButtonsRaw);
