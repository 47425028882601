import React, { FunctionComponent, useEffect } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Map, OrderedMap } from "immutable";
import { State } from "../../../store";
import { listUserOrders } from "../../../store/orders/thunks";
import { Order } from "../../../models/Order";
import { OrderList } from "./OrderList";
import { Product } from "../../../models/Product";
import { Location } from "../../../models/Location";
import { Typography } from "@material-ui/core";
import { TimeSlot } from "../../../models/TimeSlot";

interface OrdersProps extends WithStyles<typeof styles> {
  orders: Map<string, Order>;
  products: Map<string, Product>;
  timeslots: OrderedMap<string, TimeSlot>;
  locations: Map<string, Location>;
  listUserOrders: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    },
    textContainer: {
      padding: theme.spacing(4)
    }
  });

const OrdersRaw: FunctionComponent<OrdersProps> = props => {
  const { listUserOrders, orders, classes, ...rest} = props;
  const { listContainer } = classes;
  useEffect(() => { listUserOrders() }, [])

  return (
      <div className={listContainer}>
        <Typography variant="h3">Mes commandes</Typography>
        {!!orders && orders.size === 0 && "Aucune commande passée ce soir"}
        {!!orders && orders.size > 0 && <OrderList orders={orders} {...rest} />}
      </div>
  );
};

function mapStateToProps(state: State) {
 
  return {
    orders: state.orders.userOrders,
    products: state.products.list,
    locations: state.locations.list,
    timeslots: state.timeslots.list
  };
}

export const Orders = 
  withStyles(styles)(
    connect(
      mapStateToProps,
      { listUserOrders }
    )(OrdersRaw)
  )
;
