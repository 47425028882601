import React from "react";
import { connect } from "react-redux";

import { hideFlash } from "../../store/application/actions";
import { State } from "../../store";
import { FlashMessageComponent } from "../../components/FlashMessage";
import { FlashMessage } from "../../store/application/flashMessage";
import { OrderedMap } from "immutable";

interface SnackbarPresenterProps {
  flashs: OrderedMap<string, FlashMessage>;
  hideFlash: Function;
}

interface SnackbarPresenterState {
  flash: FlashMessage | undefined;
  opened: boolean;
}

class SnackbarPresenter extends React.Component<SnackbarPresenterProps, SnackbarPresenterState> {
  constructor(props: SnackbarPresenterProps) {
    super(props);
    const flash = props.flashs.first<FlashMessage>();
    this.state = { flash, opened: !!flash };
  }

  static getDerivedStateFromProps(props: SnackbarPresenterProps, state: SnackbarPresenterState) {
    const first = props.flashs.first<FlashMessage>();
    let { flash: currentFlash, opened } = state;

    // We only replace the flash when it's undefined, i.e. the animation is finished
    const flash = currentFlash || first;

    // Flash has changed, we close the current flash;
    if (first !== currentFlash) {
      opened = currentFlash === undefined && !!first;
    }
    return { flash, opened };
  }

  componentDidUpdate() {
    const { flash } = this.state;
    if (this.props.flashs.count() > 1 && flash) {
      setTimeout(() => {
        this.props.hideFlash(flash.id);
      }, 2000);
    }
  }

  handleClose = (event: React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    const { flash } = this.state;
    if (flash) this.props.hideFlash(flash.id);
  };

  // Once the flash has finished animating out, we can remove it properly
  handleExited = () => {
    this.setState({ flash: undefined });
  };

  render() {
    const { flash, opened } = this.state;
    if (!flash) return null;
    return <FlashMessageComponent flash={flash} open={opened} onClose={this.handleClose} onExited={this.handleExited} />;
  }
}

function mapStateToProps(state: State) {
  return {
    flashs: state.application.flashs
  };
}

export default connect(
  mapStateToProps,
  { hideFlash }
)(SnackbarPresenter);
