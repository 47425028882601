import React, { FunctionComponent, useState } from "react";
import { Paper, Typography, createStyles, Theme, withStyles, Grid, Switch } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { palette } from "../../../css/theme";
import { TimeSlot } from "../../../models/TimeSlot";
import { connect } from "react-redux";
import { updateTimeSlot } from "../../../store/timeslots/thunks";

interface OrderListHeaderProps extends WithStyles<typeof styles> {
  timeslot: TimeSlot;
  updateTimeSlot: (timeslot: TimeSlot) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    rootPaper: {
      background: theme.palette.primary.main,
      color: palette.white,
      marginBottom: "8px",
      padding: "4px"
    }
  });

const OrderListHeaderRaw: FunctionComponent<OrderListHeaderProps> = props => {
  const { timeslot } = props;
  const { rootPaper } = props.classes;

  const [disabled, setDisabled] = useState(false);

  const updateAvailability = async () => {
    setDisabled(true);
    const { timeslot } = props;
    timeslot.available = !timeslot.available;
    await props.updateTimeSlot(timeslot);
    setDisabled(false);
  };

  return (
    <Paper className={rootPaper} elevation={4}>
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item>
          <Typography variant="h6">{timeslot.end}</Typography>
        </Grid>
        <Grid item>
          <Switch color="secondary" disabled={disabled} checked={timeslot.available} onChange={updateAvailability}></Switch>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const OrderListHeader = connect(
  null,
  { updateTimeSlot }
)(withStyles(styles)(OrderListHeaderRaw));
