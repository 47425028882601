import React, { FunctionComponent } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../store";
import { LocationState } from "../../../store/locations/reducer";
import { Grid, Typography, Button, Paper, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { setDeliveryEnabled } from "../../../store/orders/thunks";
import { LocationList } from "./LocationList";

interface LocationListProps extends WithStyles<typeof styles> {
  locations: LocationState;
  delivery: boolean;
  setDeliveryEnabled: (enabled: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: "32px"
    },
    listContainer: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const LocationsRaw: FunctionComponent<LocationListProps> = props => {
  const { locations, classes, delivery, setDeliveryEnabled } = props;
  const { loading, list } = locations;
  const { listContainer, sectionTitle } = classes;

  const toggleDelivery = () => {
    setDeliveryEnabled(!delivery);
  };

  return (
    <React.Fragment>
      <Grid className={sectionTitle} container direction="row" justify="flex-start" spacing={1} wrap="wrap">
        <Grid item xs>
          <Typography style={{ textAlign: "left" }} variant="h2">
            Lieux
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={toggleDelivery}>
            {delivery ? "Désactiver livraison" : "Activer Livraison"}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary">
            <Link color="inherit" component={RouterLink} to="locations/new">
              Nouveau Lieu
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <div className={listContainer}>
          {loading && "Chargement des lieux"}
          {!loading && !!list && list.size === 0 && "Aucun lieu créé"}
          {!loading && !!list && list.size > 0 && <LocationList locations={list} />}
        </div>
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state: State) {
  return {
    locations: state.locations,
    delivery: state.orders.delivery
  };
}

export const Locations = withStyles(styles)(
  connect(
    mapStateToProps,
    { setDeliveryEnabled }
  )(LocationsRaw)
);
