import { Modal, ModalName } from "./modal";
import { Action } from "../index";
import { Reducer } from "redux";
import { Map, OrderedMap } from "immutable";
import { FlashMessage } from "./flashMessage";
import { AppAlert } from "./AppAlert";
import { ActionType } from "typesafe-actions";

export class ApplicationState {
  readonly initialized: boolean = false;
  readonly modals: Map<ModalName, Modal> = Map();
  readonly flashs: OrderedMap<string, FlashMessage> = OrderedMap();
  readonly appAlert?: AppAlert = undefined;
}

export type ApplicationAction = ActionType<typeof import("./actions")>;

export const applicationReducer: Reducer<ApplicationState, Action> = function(
  state = new ApplicationState(),
  action
): ApplicationState {
  switch (action.type) {
    case "APPLICATION/INITIALIZING":
      return {
        ...state,
        initialized: false
      };
    case "APPLICATION/INITIALIZED":
      return {
        ...state,
        initialized: true
      };
    case "APPLICATION/SHOW_MODAL":
      const modal = action.payload;
      const modals = state.modals.set(modal.id, modal);
      return { ...state, modals };

    case "APPLICATION/HIDE_MODAL": {
      const id = action.payload;
      const modals = state.modals.delete(id);
      return { ...state, modals };
    }

    case "APPLICATION/SHOW_FLASH": {
      const flash = action.payload;
      const flashs = state.flashs.set(flash.id, flash);
      return { ...state, flashs };
    }

    case "APPLICATION/HIDE_FLASH": {
      const id = action.payload;
      const flashs = state.flashs.delete(id);
      return { ...state, flashs };
    }

    default:
      return state;
  }
};
