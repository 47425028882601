import * as React from "react";
import { RouteProps } from "react-router-dom";
import { ConditionalRoute } from "./ConditionalRoute";

interface PrivateRouteProps extends RouteProps {
  isAdmin: boolean;
  redirectPath?: string;
}

export const AdminRoute = (props: PrivateRouteProps) => {
  const { isAdmin, redirectPath, ...rest } = props;

  return (
    <ConditionalRoute
      {...rest}
      condition={isAdmin}
      redirectPath={redirectPath || "/"}
    />
  );
};
