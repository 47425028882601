import React from "react";
import AppBar from "../../../components/AppBar";
import { actions } from "./NavBarActions";
import { MiddleButtons } from "./MiddleButtons";
import { RightButton } from "./RightButton";
import { withStyles, Theme, WithStyles, Typography } from "@material-ui/core";
import { LeftDrawer } from "./LeftDrawer";
import { connect } from "react-redux";
import { signOut } from "../../../store/auth/thunks";
import { State } from "../../../store";

const styles = (theme: Theme) => ({
  desktop: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
});

export interface NavBarProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  signOut: () => void;
  isAdmin?: boolean;
  enabled?: boolean;
}

class HomeNavBarRaw extends React.Component<NavBarProps> {
  render() {
    const { desktop, mobile } = this.props.classes;
    return (
      <React.Fragment>
        <AppBar
          className={desktop}
          leftButton={
            <Typography color="primary" variant="h3">
              Au camion magique
            </Typography>
          }
          rightButton={<RightButton action={actions.signup} />}
        >
          <MiddleButtons actions={[]} />
        </AppBar>
        <AppBar
          className={mobile}
          leftButton={<LeftDrawer hideIcons actions={[actions.home, actions.signin]} />}
          rightButton={null}
        />
      </React.Fragment>
    );
  }
}

class AdminNavBarRaw extends React.Component<NavBarProps> {
  render() {
    const { desktop, mobile } = this.props.classes;
    const signoutAction = actions.signout(this.props.signOut);
    const barActions = [actions.products, actions.timeSlots, actions.locations, actions.infos, actions.ordersAdmin];
    return (
      <React.Fragment>
        <AppBar className={desktop} leftButton={null} rightButton={<RightButton action={signoutAction} />}>
          <MiddleButtons actions={barActions} />
        </AppBar>
        <AppBar
          className={mobile}
          leftButton={<LeftDrawer hideIcons actions={[...barActions, signoutAction]} />}
          rightButton={null}
        />
      </React.Fragment>
    );
  }
}

class AppNavBarRaw extends React.Component<NavBarProps> {
  render() {
    const { desktop, mobile } = this.props.classes;
    const signoutAction = actions.signout(this.props.signOut);
    const barActions = [actions.appInfos, actions.orders];
    if (this.props.enabled) barActions.push(actions.newOrder)
    if (this.props.isAdmin) barActions.push(actions.admin)

    return (
      <React.Fragment>
        <AppBar className={desktop} leftButton={null} rightButton={<RightButton action={signoutAction} />}>
          <MiddleButtons actions={barActions} />
        </AppBar>
        <AppBar
          className={mobile}
          leftButton={<LeftDrawer hideIcons actions={[...barActions, signoutAction]} />}
          rightButton={null}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State) {
  const user = state.auth.currentUser
  return {
    enabled: state.orders.enabled,
    isAdmin: user ? user.isAdmin : false
  }
}

export const HomeNavBar = connect(
  null,
  { signOut }
)(withStyles(styles)(HomeNavBarRaw));

export const AdminNavBar = connect(
  null,
  { signOut }
)(withStyles(styles)(AdminNavBarRaw));

export const AppNavBar = connect(
  mapStateToProps,
  { signOut }
)(withStyles(styles)(AppNavBarRaw));
