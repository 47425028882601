import React from "react";
import { Typography, Paper, Grid, Button, createStyles, withStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../store";
import { RouteComponentProps } from "react-router";
import { withFormik, FormikProps, Form } from "formik";
import * as yup from "yup";
import { confirmEmail, resendEmail } from "../../../store/auth/thunks";
import { FormikTextField } from "../../../components/FormikTextField";
import { WithStyles } from "@material-ui/styles";
import Layout from "../../Layout/Layout";
import { parse } from "query-string";

type CodePayload = {
  code: string;
};

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    fields: {
      width: theme.breakpoints.width("sm"),
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "95%"
      }
    },
    paper: {
      margin: "16px"
    }
  });

interface FormikCodeVerificationProps
  extends FormikProps<CodePayload>,
    ConnectedCodeVerificationProps,
    WithStyles<typeof styles> {}

class CodeVerification extends React.Component<FormikCodeVerificationProps> {
  resendConfirmation = () => {
    const { location, resendEmail } = this.props;
    const { email } = parse(location.search);
    resendEmail(email as string);
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, history, location, isSubmitting } = this.props;
    const fieldProps = { values, errors, handleBlur, handleChange, touched };
    const { fields, sectionTitle, paper } = this.props.classes;
    const { email } = parse(location.search);

    return (
      <Layout navBar={<div />}>
        <Grid
          className={sectionTitle}
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
        >
          <Grid item xs>
            <Typography align="left" variant="h2">
              Vérification de l'email
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => history.push("/signup")}>
              Retour
            </Button>
          </Grid>
        </Grid>

        <Paper className={paper}>
          <Form>
            <Grid className={fields} container justify="center" spacing={5}>
              <Grid item xs={12}>
                <Typography> Un code de vérification a été envoyé à {email} </Typography>
                <Typography variant="body2" color="textSecondary"> Pensez à regarder vos spams </Typography>

              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="code" label="Code de vérification" fullWidth {...fieldProps} />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                      Valider
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="default" onClick={this.resendConfirmation}>
                      Renvoyer un code
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Layout>
    );
  }
}

const StyledCodeVerification = withStyles(styles)(CodeVerification);

const FormikCodeVerification = withFormik<ConnectedCodeVerificationProps, CodePayload>({
  mapPropsToValues: props => {
    return {
      code: ""
    };
  },
  handleSubmit: async (values, formikBag) => {
    const { history, confirmEmail, location } = formikBag.props;
    const { email } = parse(location.search);
    const redirect = await confirmEmail(email as string, values.code);
    if (redirect) {
      history.replace(redirect);
    } else {
      formikBag.setSubmitting(false);
    }
  },
  validationSchema: yup.object().shape({
    code: yup.string().required("Champs requis")
  }),
  validateOnBlur: true,
  validateOnChange: true
})(StyledCodeVerification);

interface ConnectedCodeVerificationProps extends RouteComponentProps<{ email: string }> {
  confirmEmail: (email: string, code: string) => Promise<string | undefined>;
  resendEmail: (email: string) => void;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<{ email: string }>) {
  return {
    ...ownProps
  };
}

export default connect(
  mapStateToProps,
  { confirmEmail, resendEmail }
)(FormikCodeVerification);
